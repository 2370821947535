import React, {useEffect, useState} from 'react';
import './index.scss';
import {Spinner} from 'react-bootstrap';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {
  fetchCreateTeams,
  fetchTournamentView,
} from '../../../../actions/Tournaments';
import Select from 'react-select';
import {SelectStyles, Select1Styles} from './SelectStyles';
import InfoModal from '../../../../components/info-modal';

const Teams = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const {teamslist} = useSelector(state => state.CommonData);
  const {athletes} = useSelector(state => state.Athletes);
  const {tournaments, isLoading, tournamentDetails} = useSelector(
    state => state.Tournaments,
  );

  const teamGroupNo = Number(
    tournamentDetails && tournamentDetails?.duo?.duo_number,
  );
  const [checkPlayerSelected, setCheckPlayerSelected] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  const leftOptions = 'left';
  const rightOptions = 'right';

  const participateSelectOptions = (side, index) => {
    if (!athletes || athletes.length === 0) return [];
    const selectedPlayersId = formik.values.teams.reduce((item, team, idx) => {
      if (team.athlete_player1_id && !item.includes(team.athlete_player1_id)) {
        item.push(team.athlete_player1_id);
      }
      if (team.athlete_player2_id && !item.includes(team.athlete_player2_id)) {
        item.push(team.athlete_player2_id);
      }
      return item;
    }, []);

    const filteredAthletes = athletes.filter(
      item => !selectedPlayersId.includes(item.id),
    );

    const options = filteredAthletes.map(item => ({
      id: item.id,
      label: item.display_name,
      value: item.id,
      ftv_points: item.ftv_points,
    }));
    return options;
  };

  const participateOptions = (side, index) => {
    if (!athletes || athletes.length === 0) return [];
    const selectedPlayersId = formik.values.teams.reduce((item, team, idx) => {
      if (index !== idx) {
        if (team.athlete_player1_id) item.push(team.athlete_player1_id);
        if (team.athlete_player2_id) item.push(team.athlete_player2_id);
      }
      return item;
    }, []);

    const filteredAthletes = athletes.filter(
      item => !selectedPlayersId.includes(item.id),
    );
    const options = filteredAthletes.map(item => ({
      id: item.id,
      label: item.display_name,
      value: item.id,
      ftv_points: item.ftv_points,
    }));
    return options;
  };

  const formatOptionLabel = ({id, label, ftv_points}) => (
    <div className="custom-option">
      <span className="option-id">{'#' + id}</span>
      <span className="option-label">{label}</span>
      <span className="option-points">
        ({t('FTV points')}: {ftv_points})
      </span>
    </div>
  );

  const validationSchema = yup.object().shape({
    // teams: yup.array().of(
    //     yup.object().shape({
    //         athlete_player1_id: yup.string().required(t("Please select a player for Team")),
    //         athlete_player2_id: yup.string().required(t("Please select a player for Team"))
    //     })
    // )
  });

  const newTeamslistArr =
    teamslist &&
    teamslist?.slice(0, teamGroupNo).map(team => {
      const teamDivisionIds = tournamentDetails?.team?.find(
        item => Number(team.team_div_name) === Number(item.team_division_id),
      );
      return (
        teamDivisionIds || {
          tournament_id: Number(id),
          team_division_id: team.team_div_name,
          athlete_player1_id: null,
          athlete_player2_id: null,
        }
      );
    });

  const formik = useFormik({
    initialValues: {
      teams:
        (!tournamentDetails?.team?.length &&
          teamslist?.length > 0 &&
          teamslist?.slice(0, teamGroupNo).map(team => ({
            tournament_id: Number(id),
            team_division_id: team.team_div_name,
            athlete_player1_id: null,
            athlete_player2_id: null,
          }))) ||
        newTeamslistArr,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async values => {
      // console.log("values===>", values);
      setCheckPlayerSelected(false);
      dispatch(fetchCreateTeams(values, formik, navigate));
    },
  });

  const handlePlayerChange = (index, fieldName, selectedValue) => {
    // const athleteId = formik.values.teams[index][fieldName];
    // if(selectedValue === null){
    //     dispatch(fetchAvailableAthleteCheck(athleteId));
    // }else{
    //     dispatch(fetchAvailableAthleteList());
    // };
    formik.setFieldValue(
      `teams[${index}].${fieldName}`,
      selectedValue ? selectedValue.id : null,
    );
    setCheckPlayerSelected(true);
  };

  const hasBlankPlayerField = teamArr => {
    for (const obj of teamArr) {
      if (!obj.athlete_player1_id || !obj.athlete_player2_id) {
        return true;
      }
    }
    return false;
  };

  const customFilter = (option, inputValue) => {
    if (inputValue.startsWith('#')) {
      const idSearch = inputValue.slice(1);
      const idRegex = new RegExp(`^${idSearch}`, 'i');
      return option.data.id.toString().match(idRegex);
    }
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  const getSortedOptions = options => {
    return options.sort((a, b) => a.id - b.id);
  };
  // useEffect(() => {
  //     dispatch(fetchAvailableAthleteList());
  // }, []);

  // console.log("formik.values.teams OUT==>", formik.values)
  // console.log("athletes==>", athletes)
  // console.log("tournamentDetails==>", tournamentDetails)
  // console.log("teamslist==>", teamslist)
  // console.log("formik==>", formik.values)

  useEffect(() => {
    dispatch(fetchTournamentView(id));
  }, [id]);

  return (
    tournamentDetails && (
      <>
        <h2 className="admin-page-title mb-4">
          {tournamentDetails &&
            tournamentDetails?.championship_name +
              ' (' +
              t(tournamentDetails?.category?.category_name) +
              ')'}
        </h2>
        <div className="loginCard profileCard mt-0 mb-3">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              {teamslist?.length > 0 &&
                teamslist?.slice(0, teamGroupNo).map((team, index) => (
                  // index % 2 === 0 ?
                  <div key={team.id} className="col-xl-6 mb-lg-4 mb-2">
                    <h6>
                      {t('Team')} {team.team_div_name}.
                    </h6>
                    <div className="mb-2">
                      <Select
                        options={getSortedOptions(
                          participateSelectOptions(leftOptions, index),
                        )}
                        onChange={option =>
                          handlePlayerChange(
                            index,
                            'athlete_player1_id',
                            option,
                          )
                        }
                        value={
                          participateOptions(leftOptions, index).find(
                            option =>
                              option.id ===
                              formik.values.teams[index]?.athlete_player1_id,
                          ) || null
                        }
                        formatOptionLabel={formatOptionLabel}
                        styles={{...SelectStyles, ...Select1Styles}}
                        placeholder={`--${t('Select Player')}--`}
                        className="custom-select-style"
                        isClearable
                        filterOption={customFilter}
                      />
                    </div>
                    <div className="mb-2">
                      <Select
                        options={getSortedOptions(
                          participateSelectOptions(rightOptions, index),
                        )}
                        onChange={option =>
                          handlePlayerChange(
                            index,
                            'athlete_player2_id',
                            option,
                          )
                        }
                        value={
                          participateOptions(rightOptions, index).find(
                            option =>
                              option.id ===
                              formik.values.teams[index]?.athlete_player2_id,
                          ) || null
                        }
                        formatOptionLabel={formatOptionLabel}
                        styles={{...SelectStyles, ...Select1Styles}}
                        placeholder={`--${t('Select Player')}--`}
                        className="custom-select-style"
                        isClearable
                        filterOption={customFilter}
                      />
                    </div>
                  </div>
                ))}
            </div>

            <div className="ctSubmit">
              {!tournamentDetails?.team?.length ? (
                <button
                  className="loginButton"
                  type="submit"
                  disabled={isLoading}>
                  {isLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <span>{t('Add')}</span>
                  )}
                </button>
              ) : (
                <>
                  <button
                    className="loginButton"
                    type="submit"
                    disabled={isLoading}>
                    {isLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <span>{t('Save Changes')}</span>
                    )}
                  </button>
                  {!checkPlayerSelected ? (
                    hasBlankPlayerField(tournamentDetails?.team) ? (
                      <button
                        onClick={() => setOpenInfoModal(true)}
                        className="loginButton ms-sm-3 mt-sm-0 mt-2 ms-0"
                        type="button">
                        {t('Preview')}
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          if (
                            tournamentDetails?.types_id === '1' ||
                            tournamentDetails?.types_id === 1
                          ) {
                            navigate(`/tournaments/double-elimination/${id}`);
                          } else {
                            navigate(`/tournaments/group-phase/${id}`);
                          }
                        }}
                        className="loginButton ms-sm-3 mt-sm-0 mt-2 ms-0"
                        type="button">
                        {t('Preview')}
                      </button>
                    )
                  ) : (
                    <button
                      onClick={() => setOpenInfoModal(true)}
                      className="loginButton ms-sm-3 mt-sm-0 mt-2 ms-0"
                      type="button">
                      <span>{t('Preview')}</span>
                    </button>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
        <button className="backtxt" onClick={() => navigate(-1)}>
          <i className="fa-light fa-arrow-left-long"></i> {t('Back')}
        </button>
        <InfoModal
          openInfoModal={openInfoModal}
          setOpenInfoModal={setOpenInfoModal}
          content={
            checkPlayerSelected
              ? t(
                  'Please click the Save Changes button to confirm your selection.',
                )
              : t('Player is blank in the team. Please select a player.')
          }
        />
      </>
    )
  );
};

export default Teams;
