import './index.scss';
import React, {useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import noUser from '../../../../assets/images/no-user.jpg';
import imgShape from '../../../../assets/images/ShieldBadge.svg';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Loader from '../../../../components/loader';
import OpeningHoursComponent from '../../../../components/opening-hours-dropdown';
import {getArenaView} from '../../../../actions/Arenas';

const ArenaView = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {arenas, isLoading, details} = useSelector(state => state.Arenas);
  // const details = arenas ? arenas.find(item => item.id === Number(id)) : {};

  // console.log("Arena details=====>", details)

  useEffect(() => {
    dispatch(getArenaView(id));
  }, [dispatch]);

  return (
    <div className="arenaBase">
      <h2 className="admin-page-title mb-3">{t('Arena Details')}</h2>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="adBg">
          <div className="adbgLft">
            <div className="adImgContent">
              <div className="adImgbg">
                {details?.profile_url ? (
                  <img
                    className="img-user"
                    src={
                      process.env.REACT_APP_IMGBASE_URL + details?.profile_url
                    }
                    alt={details?.name}
                  />
                ) : (
                  <img className="img-user" src={noUser} alt="No user" />
                )}
                <img className="img-shape" src={imgShape} alt="No user" />
              </div>
              <h6>{details?.display_name ? details.display_name : 'N/A'}</h6>
            </div>
            <div className="adbTxt">
              <table>
                <tbody>
                  <tr>
                    <th scope="col">{t('Arena Name')}</th>
                    <td data-label={t('Arena Name')}>
                      {details?.name ? details.name : 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">{t('Email Address')}</th>
                    <td data-label={t('Email Address')}>
                      {details?.email ? details?.email : 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">{t('Phone Number')}</th>
                    <td data-label={t('Phone Number')}>
                      {details?.phone ? '+' + details?.phone : 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">{t('Opening Hours')}</th>
                    <td data-label={t('Opening Hours')}>
                      {details?.opening_hours?.length > 0 ? (
                        <OpeningHoursComponent
                          openingHours={details?.opening_hours}
                        />
                      ) : (
                        'N/A'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">{t('City')}</th>
                    <td data-label={t('City')}>
                      {details?.city ? details.city : 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">{t('State')}</th>
                    <td data-label={t('State')}>
                      {details?.state ? details.state : 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">{t('Address')}</th>
                    <td data-label={t('Address')}>
                      {details?.address ? details.address : 'N/A'}
                    </td>
                  </tr>
                  {/* <tr>
                                        <th scope="col">{t("Profession")}</th>
                                        <td data-label="Profession">{details?.profession ? details.profession : "N/A"}</td>
                                    </tr> */}
                  <tr>
                    <th scope="col">{t('Championship Created')}</th>
                    <td data-label={t('Championship Created')}>
                      <span className="circleabd">
                        {details?.total_championship
                          ? details?.total_championship
                          : '0'}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <button className="backtxt" onClick={() => navigate(-1)}>
        <i className="fa-light fa-arrow-left-long"></i> {t('Back')}
      </button>
    </div>
  );
};

export default ArenaView;
