import {toast} from 'react-toastify';
import {
  TournamentList,
  ViewTournamentApi,
  AddTournament,
  UpdateTournament,
  DeleteTournament,
  createTeams,
  getGroupPhaseBrackets,
  getGroupPhaseStandings,
  groupPhaseWinnerUpdateMatch,
  getDoubleEliminationBracketData,
  doubleEliminationWinnerUpdateMatch,
  getGroupPhaseFinalData,
  getDoubleEliminationFinalData,
  startTournament,
  availableAthletesList,
  availableAthleteCheck,
} from '../services/tournament.service';
import {
  clearMessage,
  handleAddTournament,
  handleCreateTeam,
  handleDeleteTournament,
  handleGroupPhaseBrackets,
  handleGroupPhaseStanding,
  handleStartTournament,
  handleGroupPhaseUpdateMatch,
  handleFetchDataFailed,
  handleTournamentList,
  handleTournamentView,
  handleUpdateTournament,
  startLoading,
  stopLoading,
  handleDoubleEliminationBrackets,
  handleDoubleEliminationUpdateMatch,
  handleFinalList,
  handleAvailableAthleteList,
} from './Actions';

export const getTournamentList = (page, perPages, searchData) => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return TournamentList(page, perPages, searchData).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(handleTournamentList(data));
        dispatch(stopLoading());
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};

export const fetchTournamentView = itemId => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return ViewTournamentApi(itemId).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(handleTournamentView(data));
        dispatch(stopLoading());
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};

export const fetchAddTournament = (formData, formik, navigate) => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return AddTournament(formData).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(handleAddTournament(data));
        dispatch(stopLoading());
        toast.success(data.message);
        navigate(`/tournaments/teams/${data?.data?.id}`);
        formik.resetForm();
      } else {
        dispatch(stopLoading());
        dispatch(handleFetchDataFailed(data?.error));
        // toast.error(data.message);
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};

export const fetchUpdateTournament =
  (formData, formik, navigate) => dispatch => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return UpdateTournament(formData).then(
      data => {
        if (!data) return dispatch(stopLoading());
        if (data?.success) {
          dispatch(handleUpdateTournament(data));
          dispatch(getTournamentList());
          dispatch(stopLoading());
          toast.success(data.message);
          navigate('/tournaments');
          formik.resetForm();
        } else {
          dispatch(stopLoading());
          dispatch(handleFetchDataFailed(data?.error));
        }
        return Promise.resolve();
      },
      error => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(stopLoading());
        dispatch(dispatch(handleFetchDataFailed(message)));
        return Promise.reject(error);
      },
    );
  };

export const fetchDeleteTournament = itemId => dispatch => {
  // dispatch(startLoading());
  dispatch(clearMessage());
  return DeleteTournament(itemId).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(handleDeleteTournament(itemId, data.message));
        dispatch(stopLoading());
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};

export const fetchCreateTeams = teamsData => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return createTeams(teamsData).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(handleCreateTeam(data));
        dispatch(stopLoading());
        toast.success(data.message);
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};

export const fetchGroupPhaseBracketList = id => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return getGroupPhaseBrackets(id).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(handleGroupPhaseBrackets(data));
        dispatch(stopLoading());
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};

export const fetchGroupPhaseStandings = id => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return getGroupPhaseStandings(id).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(handleGroupPhaseStanding(data));
        dispatch(stopLoading());
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};

export const fetchStartTournament = itemId => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return startTournament(itemId).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(handleStartTournament(data));
        //dispatch(getTournamentList());
        dispatch(stopLoading());
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};

export const fetchGroupPhaseWinnerUpdateMatch =
  (matchUpdateObj, formik, setOpenUpdateModal, setUpdateConfirmModal) =>
  dispatch => {
    // dispatch(startLoading());
    dispatch(clearMessage());
    return groupPhaseWinnerUpdateMatch(matchUpdateObj).then(
      data => {
        if (!data) return dispatch(stopLoading());
        if (data?.success) {
          dispatch(handleGroupPhaseUpdateMatch(data));
          dispatch(fetchGroupFinalList(matchUpdateObj.tournamentId));
          dispatch(fetchGroupPhaseBracketList(matchUpdateObj.tournamentId));
          dispatch(fetchGroupPhaseStandings(matchUpdateObj.tournamentId));
          setOpenUpdateModal(false);
          setUpdateConfirmModal(false);
          formik.resetForm();
          dispatch(stopLoading());
        } else {
          dispatch(handleFetchDataFailed(data.message));
          dispatch(stopLoading());
        }
        return Promise.resolve();
      },
      error => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(stopLoading());
        dispatch(dispatch(handleFetchDataFailed(message)));
        return Promise.reject(error);
      },
    );
  };

export const fetchDoubleEliminationBracketList = id => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return getDoubleEliminationBracketData(id).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(handleDoubleEliminationBrackets(data));
        dispatch(stopLoading());
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};

export const fetchDoubleEliminationWinnerUpdateMatch =
  (matchUpdateObj, formik, setOpenUpdateModal, setUpdateConfirmModal) =>
  dispatch => {
    // dispatch(startLoading());
    dispatch(clearMessage());
    return doubleEliminationWinnerUpdateMatch(matchUpdateObj).then(
      data => {
        if (!data) return dispatch(stopLoading());
        if (data?.success) {
          dispatch(handleDoubleEliminationUpdateMatch(data));
          dispatch(
            fetchDoubleEliminationFinalList(matchUpdateObj.tournamentId),
          );
          dispatch(
            fetchDoubleEliminationBracketList(matchUpdateObj.tournamentId),
          );
          setOpenUpdateModal(false);
          setUpdateConfirmModal(false);
          formik.resetForm();
          dispatch(stopLoading());
        } else {
          dispatch(handleFetchDataFailed(data.message));
          dispatch(stopLoading());
        }
        return Promise.resolve();
      },
      error => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(stopLoading());
        dispatch(dispatch(handleFetchDataFailed(message)));
        return Promise.reject(error);
      },
    );
  };

export const fetchDoubleEliminationFinalList = id => dispatch => {
  dispatch(clearMessage());
  return getDoubleEliminationFinalData(id).then(
    data => {
      if (data?.success) {
        dispatch(handleFinalList(data));
        dispatch(stopLoading());
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};

export const fetchGroupFinalList = id => dispatch => {
  dispatch(clearMessage());
  return getGroupPhaseFinalData(id).then(
    data => {
      if (data?.success) {
        dispatch(handleFinalList(data));
        dispatch(stopLoading());
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};

export const fetchAvailableAthleteList = () => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return availableAthletesList().then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(handleAvailableAthleteList(data));
        dispatch(stopLoading());
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};

export const fetchAvailableAthleteCheck = athleteId => dispatch => {
  // dispatch(startLoading());
  dispatch(clearMessage());
  return availableAthleteCheck(athleteId).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        // dispatch(fetchAvailableAthleteList());
        dispatch(stopLoading());
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};
