import {useTranslation} from 'react-i18next';
import './index.scss';
import React from 'react';
import {useSelector} from 'react-redux';

const Dashboard = () => {
  const {t} = useTranslation();
  const {arena_count, athlete_count, total_revenue, isLoading} = useSelector(
    state => state.Cms,
  );

  return (
    <>
      <div className="dbCardbg">
        <div className="dbCard">
          <span className="dbIcon">
            <i className="fa-solid fa-user-tie" />
          </span>
          <div className='placeholder-glow'>
            <h6>{t('Arenas')}</h6>
            <h5 className='mb-0'>{isLoading ? <span className="placeholder placeholder-md w-50 rounded"></span> : arena_count}</h5>
          </div>
        </div>
        <div className="dbCard">
          <span className="dbIcon">
            <i className="fa-solid fa-person-running" />
          </span>
          <div className='placeholder-glow'>
            <h6>{t('Athletes')}</h6>
            <h5 className='mb-0'>{isLoading ? <span className="placeholder placeholder-md w-50 rounded"></span> : athlete_count}</h5>
          </div>
        </div>
        <div className="dbCard">
          <span className="dbIcon">
            <i className="fa-regular fa-square-poll-vertical"></i>
          </span>
          <div className='placeholder-glow'>
            <h6>{t('Total Revenue')}</h6>
            <h5 className='mb-0'>{isLoading ? <span className="placeholder placeholder-md w-50 rounded"></span> : `R$ ${total_revenue}`}</h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
