import {toast} from 'react-toastify';
import {
  fetchRegister,
  fetchLogin,
  fetchLogout,
  fetchUserProfileUpdate,
  fetchCheckEmailVerification,
  fetchForgotPassword,
  fetchVerifiedEmailRegisted,
  fetchUserProfile,
  fetchOpeningHoursUpdate,
} from '../services/auth.service';
import {
  clearMessage,
  loginFail,
  loginSuccess,
  registerFail,
  registerSuccess,
  startLoading,
  stopLoading,
  logoutAuth,
  profileUpdateSuccess,
  emailVerificationSuccess,
  forgotPasswordSuccess,
  userProfileSuccess,
  handleOpeningHoursUpdate,
  handleFetchDataFailed,
} from './Actions';

import {SR_TOKEN, SR_USER} from '../services/keywords';

export const register = (data, formik, navigate) => dispatch => {
  dispatch(startLoading());
  return fetchRegister(data).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(stopLoading());
        dispatch(registerSuccess(data));
        // navigate("/login");
        formik.resetForm();
      } else {
        dispatch(stopLoading());
        dispatch(registerFail(data.message));
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch(registerFail(message));
      dispatch(stopLoading());
      return Promise.reject(error);
    },
  );
};

export const login = (values, formik, navigate, t) => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return fetchLogin(values).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        if (data.token) {
          localStorage.setItem(SR_USER, JSON.stringify(data.data));
          localStorage.setItem(SR_TOKEN, JSON.stringify(data.token));
        }
        dispatch(loginSuccess(data));
        dispatch(stopLoading());
        toast.success(t('Login successful! Welcome back!'));
        navigate('/');
        formik.resetForm();
      } else {
        dispatch(loginFail(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(loginFail(message));
      return Promise.reject(error);
    },
  );
};

export const logout = () => dispatch => {
  fetchLogout();
  dispatch(logoutAuth());
};

export const getUserProfile = () => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return fetchUserProfile().then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(stopLoading());
        dispatch(userProfileSuccess(data));
        localStorage.setItem(SR_USER, JSON.stringify(data.data));
      } else {
        dispatch(stopLoading());
        dispatch(handleFetchDataFailed(data.message));
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch(handleFetchDataFailed(message));
      dispatch(stopLoading());
      return Promise.reject(error);
    },
  );
};

export const userUpdate = formData => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return fetchUserProfileUpdate(formData).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        localStorage.setItem(SR_USER, JSON.stringify(data.data));
        dispatch(stopLoading());
        dispatch(profileUpdateSuccess(data));
      } else {
        dispatch(stopLoading());
        dispatch(handleFetchDataFailed(data.message));
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch(handleFetchDataFailed(message));
      dispatch(stopLoading());
      return Promise.reject(error);
    },
  );
};

export const openingHoursUpdate =
  (data, formik, setOpenHourModal) => dispatch => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchOpeningHoursUpdate(data).then(
      data => {
        if (!data) return dispatch(stopLoading());
        if (data?.success) {
          dispatch(stopLoading());
          dispatch(handleOpeningHoursUpdate(data));
          setOpenHourModal(false);
          formik.resetForm();
        } else {
          dispatch(stopLoading());
          dispatch(handleFetchDataFailed(data.message));
        }
        return Promise.resolve();
      },
      error => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(handleFetchDataFailed(message));
        dispatch(stopLoading());
        return Promise.reject(error);
      },
    );
  };

export const emailVerification = (values, formik) => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return fetchCheckEmailVerification(values).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(stopLoading());
        dispatch(emailVerificationSuccess(data));
        formik.resetForm();
      } else {
        dispatch(stopLoading());
        dispatch(handleFetchDataFailed(data.message));
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch(handleFetchDataFailed(message));
      dispatch(stopLoading());
      return Promise.reject(error);
    },
  );
};

export const resetForgotPasssword =
  (token, values, formik, setShowSuccessModal) => dispatch => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchForgotPassword(token, values).then(
      data => {
        if (!data) return dispatch(stopLoading());
        if (data?.success) {
          dispatch(stopLoading());
          dispatch(forgotPasswordSuccess(data));
          setShowSuccessModal(true);
          formik.resetForm();
        } else {
          dispatch(stopLoading());
          dispatch(handleFetchDataFailed(data.message));
        }
        return Promise.resolve();
      },
      error => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(handleFetchDataFailed(message));
        dispatch(stopLoading());
        return Promise.reject(error);
      },
    );
  };

export const verifiedEmail = token => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return fetchVerifiedEmailRegisted(token).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(stopLoading());
        dispatch(emailVerificationSuccess(data));
      } else {
        dispatch(stopLoading());
        dispatch(handleFetchDataFailed(data.message));
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch(handleFetchDataFailed(message));
      dispatch(stopLoading());
      return Promise.reject(error);
    },
  );
};
