import React from 'react';
import './index.scss';

export const ArenaListPlaceholder = () => {
  return Array.from({length: 25}, (_, index) => (
    <tr key={index} className="card-text placeholder-glow">
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>

      <td>
        <span className="placeholder placeholder-sm sp-btn-sty"></span>
      </td>
    </tr>
  ));
};

export const AthleteListPlaceholder = () => {
  return Array.from({length: 25}, (_, index) => (
    <tr key={index} className="card-text placeholder-glow">
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm sp-btn-sty"></span>
      </td>
    </tr>
  ));
};

export const TournamentListPlaceholder = () => {
  return Array.from({length: 25}, (_, index) => (
    <tr key={index} className="card-text placeholder-glow">
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>

      <td>
        <span className="placeholder placeholder-sm sp-btn-sty"></span>
        <span className="placeholder placeholder-sm sp-btn-sty"></span>
      </td>
    </tr>
  ));
};

export const NewsListPlaceholder = () => {
  return Array.from({length: 25}, (_, index) => (
    <tr key={index} className="card-text placeholder-glow">
      <td>
        <span className="placeholder placeholder-sm sp-img-sty"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm sp-btn-sty"></span>
        <span className="placeholder placeholder-sm sp-btn-sty"></span>
      </td>
    </tr>
  ));
};

export const FrontEndPlaceholder = () => {
  return (
    <div className="row">
      {Array.from({length: 16}, (_, index) => (
        <div key={index} className="col-md-6 col-lg-3 py-3 placeholder-glow">
          <div className="placeHolderCard">
            <span className="placeholder placeholder-sm card-img-sty height-240"></span>
            <div className='placeHolderBody d-flex flex-column'>
              <span className="placeholder placeholder-sm w-50 rounded"></span>
              <span className="placeholder placeholder-xs mt-2 w-25 rounded"></span>
              <span className="placeholder placeholder-sm mt-3 placeholder-btn"></span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const ChampionshipPlaceholder = () => {
  return (
    <div className="placeHolderCard placeholder-glow">
      <span className="placeholder placeholder-sm card-img-sty height-320"></span>
      <div className='placeHolderBody d-flex flex-column'>
        <span className="placeholder placeholder-sm w-50 rounded"></span>
        <span className="placeholder placeholder-xs mt-2 w-25 rounded"></span>
      </div>
    </div>
  );
};

export const RankingPlaceholder = () => {
  return (
    <div className="row">
      {Array.from({length: 20}, (_, index) => (
        <div key={index} className="col-md-6 col-lg-3 py-3 placeholder-glow">
          <div className="ranking-item ranking-placeholder-sty">
            <span className="placeholder placeholder-sm ranking-item-img"></span>
            <div className='ranking-item-info w-100'>
              <span className="placeholder placeholder-md mt-1 w-25 rounded"></span>
              <span className="placeholder placeholder-xs mt-2 w-50 rounded"></span>
              <span className="placeholder placeholder-sm mt-3 w-75 rounded"></span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

