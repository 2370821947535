import './index.scss';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import moment from 'moment';
import noImg from '../../../assets/images/no-image.jpg';
import {getNewsList} from '../../../actions/Cms';
import BannerBar from '../components/banner-bar';
import bannerImg from '../../../assets/images/news-banner.png';
import PagePagination from '../../../components/pagination';
import { FrontEndPlaceholder } from '../../../components/skeleton-placeholder';

const News = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const {news_list, isLoading, perPageNews, totalNews} = useSelector(
    state => state.Cms,
  );
  const onChangePaginate = number => {
    dispatch(getNewsList(number, perPageNews, {}));
    navigate(`?page=${number}`);
  };
  
  useEffect(() => {
    dispatch(getNewsList(page ? page : 1, perPageNews, {}));
  }, [dispatch, page]);

  return (
    <>
      <div className="mainBase pt-0">
        <BannerBar
          title={'News'}
          subTitle={
            'Stay up to date with the news! Follow our networks @sport.ranking.ftv!'
          }
          description={'The right point in your game.'}
          bannerUrl={bannerImg}
          linkUrl={'/championships'}
        />
        <div className="cmn-content-body news-wrapper">
          <div className="container">
            <div className="row">
              {isLoading ? (
               <FrontEndPlaceholder />
              ) : news_list && news_list.length > 0 ? (
                news_list.map(item => (
                  <div key={item.id} className="col-md-6 col-lg-3 py-3">
                    <div
                      className="championCard"
                      onClick={() => navigate(`/news/view/${item?.id}`)}>
                      <div className="championCardImg height-240">
                        {item?.news_url ? (
                          <img
                            src={
                              process.env.REACT_APP_IMGBASE_URL + item?.news_url
                            }
                            alt=""
                          />
                        ) : (
                          <img src={noImg} alt="" />
                        )}
                      </div>
                      <div className="cardTxt">
                        <div className="cardHdn">
                          <h6>{item?.title}</h6>
                          <p>{item?.description?.slice(0, 150)} </p>
                          <button className="btn btn-read-more">
                            {t('Read More')}
                          </button>
                        </div>
                        <div className="cardDate">
                          <h5>{moment(item.created_at).format('DD')}</h5>
                          <h6>{moment(item.created_at).format('MMM')}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center">
                  <p className="mb-0">
                    {t('News')} {t('Not Found')}.
                  </p>
                </div>
              )}
              <div className="d-flex justify-content-end">
                <PagePagination
                  totalItems={totalNews}
                  currentPage={page ? page : 1}
                  pageSize={perPageNews}
                  onPageChange={onChangePaginate}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default News;
