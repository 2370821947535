import React, { useEffect, useState } from 'react';
import './index.scss';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ChampionshipItem from '../components/ChampionshipItem';
import { useTranslation } from 'react-i18next';
import {
    getPreviousTournament,
    getUpcomingTournament,
} from '../../../actions/Cms';
import BannerBar from '../components/banner-bar';
import bannerImg from '../../../assets/images/championships-banner.png';
import PagePagination from '../../../components/pagination';
import { ChampionshipPlaceholder } from '../../../components/skeleton-placeholder';

const Championships = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        upcoming_tournament,
        previous_tournament,
        isLoading,
        perPageTournaments,
        totalTournaments,
        totalUpcomingTournaments,
    } = useSelector(state => state.Cms);
    const [currentPage, setCurrentPage] = useState(1);
    const [upcomingPage, setUpcomingPage] = useState(1);
    const [defaultKey, setDefaultKey] = useState('upcoming');

    useEffect(() => {
        dispatch(getUpcomingTournament(1, perPageTournaments, {}));
        setDefaultKey('upcoming');
        dispatch(getPreviousTournament(1, perPageTournaments, {}));
    }, [dispatch]);

    const onChangePaginate = (number, type) => {
        setDefaultKey(type);
        const searchData = {
            query: '',
            state: '',
            city: '',
        };
        if (type == 'previous') {
            setCurrentPage(number);
            dispatch(getPreviousTournament(number, perPageTournaments, searchData));
        } else {
            setUpcomingPage(number);
            dispatch(getUpcomingTournament(number, perPageTournaments, searchData));
        }
    };

    return (
        <>
            <BannerBar
                title={'Events and Championships'}
                subTitle={'A Year of Triumph and Competition'}
                description={'The right point in your game.'}
                bannerUrl={bannerImg}
                linkUrl={'/championships'}
            />
            <div className="mainBase pt-0">
                <div className="cmn-content-body championships-wrapper">
                    <div className="container">
                        <Tabs
                            defaultActiveKey={defaultKey}
                            transition={false}
                            className="cmn-tabs-style">
                            <Tab eventKey="upcoming" title={t('Up Coming')}>
                                {isLoading ? (
                                    <div className="row">
                                        {Array.from({ length: perPageTournaments }, (_, index) => (
                                            <div key={index} className="col-md-6 col-lg-4 py-3">
                                                <ChampionshipPlaceholder />
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="row">
                                        {upcoming_tournament && upcoming_tournament.length > 0 ? (
                                            upcoming_tournament.map(item => {
                                                return (
                                                    <div key={item.id} className="col-md-6 col-lg-4 py-3">
                                                        <ChampionshipItem item={item} />
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="col-md-12 col-lg-12 text-center pt-3">
                                                <p className="mb-0">
                                                    {t('Up Coming')} {t('Championships / Tournament')}{' '}
                                                    {t('Not Found.')}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className="d-flex justify-content-end mt-3">
                                    <PagePagination
                                        totalItems={totalUpcomingTournaments}
                                        currentPage={upcomingPage}
                                        pageSize={perPageTournaments}
                                        onPageChange={number =>
                                            onChangePaginate(number, 'upcoming')
                                        }
                                        loading={isLoading}
                                    />
                                </div>
                            </Tab>
                            <Tab eventKey="previous" title={t('Previous')}>
                            {isLoading ? (
                                    <div className="row">
                                        {Array.from({ length: perPageTournaments }, (_, index) => (
                                            <div key={index} className="col-md-6 col-lg-4 py-3">
                                                <ChampionshipPlaceholder />
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                <div className="row">
                                    {previous_tournament && previous_tournament.length > 0 ? (
                                        previous_tournament.map(item => {
                                            return (
                                                <div key={item.id} className="col-md-6 col-lg-4 py-3">
                                                    <ChampionshipItem item={item} />
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="col-md-12 col-lg-12 text-center pt-3">
                                            <p className="mb-0">
                                                {t('Previous')} {t('Championships / Tournament')}{' '}
                                                {t('Not Found.')}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                )}
                                <div className="d-flex justify-content-end mt-3">
                                    <PagePagination
                                        totalItems={totalTournaments}
                                        currentPage={currentPage}
                                        pageSize={perPageTournaments}
                                        onPageChange={number =>
                                            onChangePaginate(number, 'previous')
                                        }
                                        loading={isLoading}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Championships;
