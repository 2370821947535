import './index.scss';
import React, {useEffect} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchTournamentView,
  fetchUpdateTournament,
} from '../../../../actions/Tournaments';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Alert, Spinner} from 'react-bootstrap';
import {clearMessage} from '../../../../actions/Actions';
import ImageUploadField from '../../../../components/image-upload-field';
import {
  DateSelectField,
  TimeSelectField,
} from '../../../../components/datetime-select-field';
import moment from 'moment';

const EditTournament = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const {tournaments, message, isError, isLoading, tournamentDetails} =
    useSelector(state => state.Tournaments);
  const {duoslist, categorieslist, typeslist} = useSelector(
    state => state.CommonData,
  );

  useEffect(() => {
    dispatch(fetchTournamentView(id));
  }, [id]);

  const formik = useFormik({
    initialValues: {
      id: tournamentDetails?.id || '',
      pic_url: tournamentDetails?.pic_url || '',
      championship_name: tournamentDetails?.championship_name || '',
      category_name: tournamentDetails?.category?.category_name || '',
      gender: tournamentDetails?.gender || '',
      type_name: tournamentDetails?.type?.type_name || '',
      no_group: tournamentDetails?.no_group || '',
      no_teams_final: tournamentDetails?.no_teams_final || '',
      no_duos: tournamentDetails?.duo?.duo_number || '',
      from_date: tournamentDetails?.from_date || '',
      to_date: tournamentDetails?.to_date || '',
      from_time: tournamentDetails?.from_time || '',
      to_time: tournamentDetails?.to_time || '',
      previous_month:
        tournamentDetails?.previous_month ||
        moment().subtract(1, 'month').startOf('month').format('MMMM'),
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      championship_name: yup.string().required(t('Please championship name.')),
      category_name: yup.string().required(t('Please enter category name.')),
      gender: yup.string().required(t('Please select gender.')),
      type_name: yup.string().required(t('Please select type.')),
      no_group: yup.string().when('type_name', {
        is: val => val === 'Group Phase',
        then: () => yup.string().required(t('Please select number of group.')),
        otherwise: () => yup.string(),
      }),
      no_teams_final: yup.string().when('type_name', {
        is: val => val === 'Group Phase',
        then: () =>
          yup.string().required(t('Please select number of teams for final.')),
        otherwise: () => yup.string(),
      }),
      no_duos: yup.string().required(t('Please select number of duos.')),
      from_date: yup.string().required(t('Please enter from date.')),

      from_time: yup.string().required(t('Please enter from time.')),
    }),
    onSubmit: async values => {
      // console.log("values===>", values)
      const formData = new FormData();
      if (
        values.pic_url === undefined ||
        values.pic_url === null ||
        values.pic_url === ''
      ) {
        formData.append('pic_url', values.pic_url);
      } else {
        formData.append('pic_url', values.pic_url);
      }
      formData.append('id', values.id);
      formData.append('championship_name', values.championship_name);
      formData.append('category_name', values.category_name);
      formData.append('gender', values.gender);
      formData.append('type_name', values.type_name);
      formData.append('no_duos', values.no_duos);
      formData.append('from_date', values.from_date);
      formData.append('to_date', values.to_date);
      formData.append('from_time', values.from_time);
      formData.append('to_time', values.to_time);
      formData.append('previous_month', values.previous_month);
      if (values.type_name === 'Group Phase') {
        formData.append('no_group', values.no_group);
        formData.append('no_teams_final', values.no_teams_final);
      }
      dispatch(fetchUpdateTournament(formData, formik, navigate));
    },
  });

  // console.log(formik.values)

  useEffect(() => {
    if (formik.values.type_name !== 'Group Phase') {
      formik.setFieldValue('no_group', '');
      formik.setFieldError('no_group', '');
      formik.setFieldTouched('no_group', false);
      formik.setFieldValue('no_teams_final', '');
      formik.setFieldError('no_teams_final', '');
      formik.setFieldTouched('no_teams_final', false);
    }
    dispatch(clearMessage());
  }, [dispatch, formik.values.type_name]);

  return (
    <>
      <h2 className="admin-page-title bordrBtm mb-3 pb-3">
        {t('Edit Tournament')}
      </h2>
      <div className="ctFirst t-add-mt-2">
        <div className="loginCard profileCard mb-3">
          <div className="profileUpload mb-4">
            <ImageUploadField
              imgType={'image'}
              imgUrl={formik?.values?.pic_url}
              fieldName={'pic_url'}
              formik={formik}
            />
          </div>
          {message && isError && typeof message === 'object' && (
            <Alert
              className="mb-3"
              variant="danger"
              onClose={() => dispatch(clearMessage())}
              dismissible>
              {message?.championship_name &&
                message?.championship_name.map(item => item)}
              {message?.category_name &&
                message?.category_name.map(item => item)}
              {message?.gender && message?.gender.map(item => item)}
              {message?.type_name && message?.type_name.map(item => item)}
              {message?.no_group && message?.no_group.map(item => item)}
              {message?.no_teams_final &&
                message?.no_teams_final.map(item => item)}
              {message?.no_duos && message?.no_duos.map(item => item)}
              {message?.from_date && message?.from_date.map(item => item)}
              {message?.to_date && message?.to_date.map(item => item)}
              {message?.from_time && message?.from_time.map(item => item)}
              {message?.to_time && message?.to_time.map(item => item)}
            </Alert>
          )}
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel
                  controlId="ChampionshipName"
                  label={t('Name of the Championship')}
                  className="cmn-style-floating">
                  <Form.Control
                    type="text"
                    name="championship_name"
                    placeholder="Enter championship"
                    onChange={formik.handleChange}
                    value={formik.values.championship_name}
                  />
                </FloatingLabel>
                {formik.touched.championship_name &&
                formik.errors.championship_name ? (
                  <p className="errorTxt">{formik.errors.championship_name}</p>
                ) : null}
              </div>
              <div className="col-md-12 mb-3">
                <FloatingLabel
                  controlId="CategoryName"
                  label={t('Category')}
                  className={`${
                    formik.values.category_name
                      ? 'cmn-style-floating active'
                      : 'cmn-style-floating select-style'
                  }`}>
                  <Form.Select
                    disabled
                    name="category_name"
                    onChange={formik.handleChange}
                    value={formik.values.category_name}>
                    <option value=""></option>
                    {categorieslist?.length > 0 &&
                      categorieslist.map(item => (
                        <option key={item.id} value={item.category_name}>
                          {t(item.category_name)}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
                {formik.touched.category_name && formik.errors.category_name ? (
                  <p className="errorTxt">{formik.errors.category_name}</p>
                ) : null}
              </div>
              <div className="col-md-12 mb-3">
                <FloatingLabel
                  controlId="Gender"
                  label={t('Gender')}
                  className={`${
                    formik.values.gender
                      ? 'cmn-style-floating active'
                      : 'cmn-style-floating select-style'
                  }`}>
                  <Form.Select
                    name="gender"
                    onChange={formik.handleChange}
                    value={formik.values.gender}>
                    <option value=""></option>
                    <option value="Male">{t('Male')}</option>
                    <option value="Female">{t('Female')}</option>
                    {/* <option value="Mixed">Mixed</option> */}
                  </Form.Select>
                </FloatingLabel>
                {formik.touched.gender && formik.errors.gender ? (
                  <p className="errorTxt">{formik.errors.gender}</p>
                ) : null}
              </div>
              <div className="col-md-12 mb-3">
                <FloatingLabel
                  controlId="TypeName"
                  label={t('Type')}
                  className={`${
                    formik.values.type_name
                      ? 'cmn-style-floating active'
                      : 'cmn-style-floating select-style'
                  }`}>
                  <Form.Select
                    disabled
                    name="type_name"
                    onChange={formik.handleChange}
                    value={formik.values.type_name}>
                    <option value=""></option>
                    {typeslist?.length > 0 &&
                      typeslist.map(item => (
                        <option key={item.id} value={item.type_name}>
                          {t(item.type_name)}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
                {formik.touched.type_name && formik.errors.type_name ? (
                  <p className="errorTxt">{formik.errors.type_name}</p>
                ) : null}
              </div>
              {formik.values.type_name === 'Group Phase' && (
                <>
                  <div className="col-md-12 mb-3">
                    <FloatingLabel
                      controlId="NoOfGroup"
                      label={t('Number of Group')}
                      className={`${
                        formik.values.no_group
                          ? 'cmn-style-floating active'
                          : 'cmn-style-floating select-style'
                      }`}>
                      <Form.Select
                        disabled
                        name="no_group"
                        onChange={formik.handleChange}
                        value={formik.values.no_group}>
                        <option value=""></option>
                        <option value="2">2</option>
                        <option value="4">4</option>
                        <option value="8">8</option>
                      </Form.Select>
                    </FloatingLabel>
                    {formik.touched.no_group && formik.errors.no_group ? (
                      <p className="errorTxt">{formik.errors.no_group}</p>
                    ) : null}
                  </div>
                  <div className="col-md-12 mb-3">
                    <FloatingLabel
                      controlId="NoOfTeamFinal"
                      label={t('Number of Teams for Final')}
                      className={`${
                        formik.values.no_teams_final
                          ? 'cmn-style-floating active'
                          : 'cmn-style-floating select-style'
                      }`}>
                      <Form.Select
                        disabled
                        name="no_teams_final"
                        onChange={formik.handleChange}
                        value={formik.values.no_teams_final}>
                        <option value=""></option>
                        <option value="4">4</option>
                        <option value="8">8</option>
                      </Form.Select>
                    </FloatingLabel>
                    {formik.touched.no_teams_final &&
                    formik.errors.no_teams_final ? (
                      <p className="errorTxt">{formik.errors.no_teams_final}</p>
                    ) : null}
                  </div>
                </>
              )}
              <div className="col-md-12 mb-3">
                <FloatingLabel
                  controlId="NoDuos"
                  label={t('Number of Duos')}
                  className={`${
                    formik.values.no_duos
                      ? 'cmn-style-floating active'
                      : 'cmn-style-floating select-style'
                  }`}>
                  <Form.Select
                    disabled
                    name="no_duos"
                    onChange={formik.handleChange}
                    value={formik.values.no_duos}>
                    <option value=""></option>
                    {duoslist?.length > 0 &&
                      duoslist.map(item => (
                        <option key={item.id} value={item.duo_number}>
                          {item.duo_number}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
                {formik.touched.no_duos && formik.errors.no_duos ? (
                  <p className="errorTxt">{formik.errors.no_duos}</p>
                ) : null}
              </div>

              <div className="col-md-6 mb-3">
                <DateSelectField
                  formik={formik}
                  fieldName={'from_date'}
                  labelName={t('From Date')}
                />
              </div>

              <div className="col-md-6 mb-3">
                <TimeSelectField
                  formik={formik}
                  fieldName={'from_time'}
                  labelName={t('From Time')}
                />
              </div>
            </div>
            <button className="loginButton" type="submit" disabled={isLoading}>
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <span>{t('Save')}</span>
              )}
            </button>
          </form>
        </div>
        <Link to="/tournaments" className="backtxt">
          <i className="fa-light fa-arrow-left-long"></i> {t('Back')}
        </Link>
      </div>
    </>
  );
};

export default EditTournament;
