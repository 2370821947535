import axios from 'axios';
import authHeader from './auth-header';
import {toast} from 'react-toastify';
import {SR_DEVICE_TOKEN, SR_TOKEN, SR_USER} from './keywords';

const API_URL = process.env.REACT_APP_API_URL + 'arena/';

export const fetchRegister = data => {
  return axios
    .post(API_URL + 'registration', data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Register failed: ${error.message}`);
    });
};

export const fetchLogin = values => {
  return axios
    .post(API_URL + 'login', values)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Login failed: ${error.message}`);
    });
};

export const fetchLogout = () => {
  localStorage.removeItem(SR_USER);
  localStorage.removeItem(SR_TOKEN);
  localStorage.removeItem(SR_DEVICE_TOKEN);
};

export const fetchUserProfile = () => {
  return axios
    .get(API_URL + `profile-view`, {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Profile view Failed: ${error.message}`);
    });
};

export const fetchUserProfileUpdate = formData => {
  return axios
    .post(API_URL + 'ProfileUpdate', formData, {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`User profile update failed: ${error.message}`);
    });
};

export const fetchOpeningHoursUpdate = data => {
  return axios
    .post(API_URL + 'openinghour-update', data, {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Opening hours update failed: ${error.message}`);
    });
};

export const fetchCheckEmailVerification = data => {
  return axios
    .post(API_URL + 'forgot-password-email', data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Email varification failed: ${error.message}`);
    });
};

export const fetchForgotPassword = (token, data) => {
  return axios
    .post(API_URL + `new-password/${token}`, data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Forgot passwod failed: ${error.message}`);
    });
};

export const fetchVerifiedEmailRegisted = token => {
  return axios
    .get(API_URL + `verified-mail/${token}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Verified email registed Failed: ${error.message}`);
    });
};

export const fetchDeviceToken = device_token => {
  return axios
    .post(
      API_URL + `device-token/store`,
      {device_token: device_token},
      {headers: authHeader()},
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Device token Failed: ${error.message}`);
    });
};

export const changeLanguageApi = lng => {
  return axios
    .post(API_URL + `language-change`, {language: lng}, {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Language Failed: ${error.message}`);
    });
};
