import axios from 'axios';
import authHeader from './auth-header';
import {toast} from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL + 'arena/';

export const fetchArenaList = (page, perPages, searchData) => {
  return axios
    .post(
      API_URL + `arena-profile-view?page=${page}&pageSize=${perPages}`,
      searchData,
      {
        headers: authHeader(),
      },
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Arena list failed: ${error.message}`);
    });
};

export const fetchArenaView = itemId => {
  return axios
    .get(API_URL + `arena-profile/${itemId}`, {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Arena view failed: ${error.message}`);
    });
};
