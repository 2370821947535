import {
  ARENA_SUCCESS,
  ARENA_VIEW,
  CITY_FIELD,
  CLEAR_MESSAGE,
  CURRENT_PAGE,
  DATA_FAILED,
  SEARCH_FIELD,
  START_LOADING,
  STATE_FIELD,
  STOP_LOADING,
} from '../actions/Types';

const initialState = {
  arenas: [],
  query: '',
  state: '',
  city: '',
  details: {},
  currentPage: 1,
  totalArenas: 1,
  message: null,
  isError: false,
  isLoading: false,
};
export default function Arenas(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case ARENA_SUCCESS:
      // console.log('===payload====>', payload);
      return {
        ...state,
        arenas: payload.arenas,
        currentPage: payload.currentPage,
        totalArenas: payload.totalArenas,
      };
    case SEARCH_FIELD:
      return {
        ...state,
        query: payload.query,
      };
    case STATE_FIELD:
      return {
        ...state,
        state: payload.state,
      };
    case CITY_FIELD:
      return {
        ...state,
        city: payload.city,
      };
    case CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload.currentPage,
      };

    case DATA_FAILED:
      return {
        ...state,
        message: payload.message,
      };
    case ARENA_VIEW:
      return {
        ...state,
        details: payload.details,
      };
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: null,
        isError: false,
      };
    default:
      return state;
  }
}
