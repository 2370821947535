import './index.scss';
import React, {useEffect, useState} from 'react';
import noImg from '../../../../../../assets/images/no-image.jpg';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchStartTournament,
  fetchTournamentView,
} from '../../../../../../actions/Tournaments';
import InfoModal from '../../../../../../components/info-modal';
import {useNavigate} from 'react-router-dom';

const BannerHeader = ({
  arenaId,
  tournamentId,
  picUrl,
  tournamentName,
  duoNumber,
  categoryType,
  status,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {tournament, tournaments, tournamentDetails} = useSelector(
    state => state.Tournaments,
  );

  const [openInfoModal, setOpenInfoModal] = useState(false);

  //   useEffect(() => {
  //     dispatch(fetchTournamentView(tournamentId));
  //   }, [tournamentId]);

  const hasBlankPlayerField = teamArr => {
    for (const obj of teamArr) {
      if (!obj.athlete_player1_id || !obj.athlete_player2_id) {
        return true;
      }
    }
    return false;
  };

  console.log('===tournamentDetails==>', tournamentId, tournamentDetails);

  return (
    <>
      <div className="tournament-header">
        <button className="back-link" onClick={() => navigate(-1)}>
          <i className="fa-solid fa-chevron-left"></i>{' '}
        </button>
        <div className="tournament-body-bx ps-3">
          <div className="mlCardImg">
            {picUrl ? (
              <img
                className="img-user"
                src={process.env.REACT_APP_IMGBASE_URL + picUrl}
                alt={tournamentName}
              />
            ) : (
              <img className="img-user" src={noImg} alt="No user" />
            )}
          </div>
          <div className="tournament-content">
            <h4>
              {tournamentName ? tournamentName : 'N/A'}{' '}
              {categoryType ? '(' + t(categoryType) + ')' : ''}
            </h4>
            <span>
              {duoNumber ? (
                <>
                  <i className="fa-light fa-user-group"></i> {duoNumber}{' '}
                  {t('Teams')}{' '}
                </>
              ) : (
                'N/A'
              )}
            </span>
          </div>
        </div>
        {tournament?.status === 1 && (
          <div className="tournament-header-right">
            {tournamentDetails?.team &&
            hasBlankPlayerField(tournamentDetails?.team) ? (
              <button
                onClick={() => setOpenInfoModal(true)}
                className="addBtns ms-3"
                type="button">
                <i className="fa-regular fa-circle-play"></i>{' '}
                <span>{t('Start Tournament')}</span>
              </button>
            ) : (
              <button
                onClick={() => dispatch(fetchStartTournament(tournamentId))}
                className="addBtns ms-3"
                type="button">
                <i className="fa-regular fa-circle-play"></i>{' '}
                <span>{t('Start Tournament')}</span>
              </button>
            )}
          </div>
        )}
        {status === 2 && (
          <div className="live-txt">
            <span>
              <i className="fa-solid fa-circle-small"></i>&nbsp;{t('Live')}
            </span>
          </div>
        )}
      </div>
      <InfoModal
        openInfoModal={openInfoModal}
        setOpenInfoModal={setOpenInfoModal}
        content={t('Player is blank in the team. Please select a player.')}
      />
    </>
  );
};

export default BannerHeader;
