import React from 'react';
import './index.scss';
import {FloatingLabel, Form} from 'react-bootstrap';
import {State, City} from 'country-state-city';

export const country = 'BR';

export const StateSelectField = ({
  fieldName,
  labelName,
  searchStateTerm,
  handleChange,
}) => {
  return (
    <>
      <FloatingLabel
        controlId={fieldName}
        label={labelName}
        className={`${
          searchStateTerm
            ? 'cmn-style-floating active'
            : 'cmn-style-floating select-style'
        }`}>
        <Form.Select
          name={fieldName}
          onChange={handleChange}
          value={searchStateTerm}>
          <option value=""></option>
          {State.getStatesOfCountry(country).map((item, index) => {
            return (
              <option key={index} value={item.isoCode}>
                {item.name}
              </option>
            );
          })}
        </Form.Select>
      </FloatingLabel>
    </>
  );
};

export const CitySelectField = ({
  fieldName,
  labelName,
  selectedStateName,
  searchCityTerm,
  handleChange,
}) => {
  return (
    <>
      <FloatingLabel
        controlId={fieldName}
        label={labelName}
        className={`${
          searchCityTerm
            ? 'cmn-style-floating active'
            : 'cmn-style-floating select-style'
        }`}>
        <Form.Select
          name={fieldName}
          onChange={handleChange}
          value={searchCityTerm}>
          <option value=""></option>
          {City.getCitiesOfState(country, selectedStateName).map(
            (item, index) => {
              return (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              );
            },
          )}
        </Form.Select>
      </FloatingLabel>
    </>
  );
};

export const SearchInputField = ({
  fieldName,
  labelName,
  searchTerm,
  handleChange,
}) => {
  return (
    <FloatingLabel controlId={fieldName} label={labelName} className="ms-3">
      <Form.Control
        type="text"
        name={fieldName}
        placeholder="Search here"
        value={searchTerm}
        onChange={handleChange}
        autoComplete="off"
      />
    </FloatingLabel>
  );
};
