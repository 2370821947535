import {fetchArenaList, fetchArenaView} from '../services/arena.service';
import {
  clearMessage,
  handleArenaSuccess,
  handleArenaView,
  handleFetchDataFailed,
  startLoading,
  stopLoading,
} from './Actions';

export const getArenaList = (page, perPages, searchData) => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return fetchArenaList(page, perPages, searchData).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(handleArenaSuccess(data));
        dispatch(stopLoading());
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};

export const getArenaView = itemId => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return fetchArenaView(itemId).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(handleArenaView(data));
        dispatch(stopLoading());
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};
