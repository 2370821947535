import {
  ABOUT_US,
  ARENAS_LIST,
  ARENAS_VIEW,
  ARENA_TOURNAMENT_LIST,
  CLEAR_MESSAGE,
  CONTACT_INFO,
  CONTACT_US,
  DATA_FAILED,
  HELP_LIST,
  NEWS_LIST,
  NEWS_VIEW,
  PREVIOUS_TOURNAMENT,
  PRIVACY_POLICY,
  RANKING_LIST,
  START_LOADING,
  STOP_LOADING,
  TERMS_CONDITIONS,
  TOURNAMENT_DETAILS,
  UPCOMING_TOURNAMENT,
  GET_DASHBOARD,
  FEMALE_RANKING_LIST,
  MALE_RANKING_LIST,
} from '../actions/Types';

const initialState = {
  news_list: [],
  news_details: {},
  athletes_female: [],
  athletes_male: [],
  terms_conditions: {},
  privacy_policy: {},
  about_us: {},
  help_list: [],
  upcoming_tournament: [],
  previous_tournament: [],
  arenas_list: [],
  arena_details: {},
  contact_info: {},
  arena_tournament_list: [],
  message: null,
  isError: false,
  isLoading: false,
  arena_count: 0,
  athlete_count: 0,
  total_revenue: 0,
  totalNews: 0,
  perPageNews: 16,
  perPageArenas: 16,
  totalArenas: 1,
  totalTournaments: 1,
  totalUpcomingTournaments: 0,
  perPageTournaments: 12,
  perPageRanking: 20,
  totalMaleRanking: 10,
  totalFemaleRanking: 10,
};
export default function Cms(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case ARENAS_LIST:
      return {
        ...state,
        totalArenas: payload.totalArenas,
        arenas_list: payload.arenas_list.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        ),
      };
    case ARENAS_VIEW:
      return {
        ...state,
        arena_details: payload.arena_details,
      };
    case NEWS_LIST:
      return {
        ...state,
        totalNews: payload.totalNews,
        news_list: payload.news_list.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        ),
      };
    case NEWS_VIEW:
      return {
        ...state,
        news_details: payload.news_details,
      };
    case RANKING_LIST:
      return {
        ...state,
        totalMaleRanking: payload.totalMaleRanking,
        totalFemaleRanking: payload.totalFemaleRanking,
        athletes_female: payload.athletes_female,
        athletes_male: payload.athletes_male,
      };

    case MALE_RANKING_LIST:
      return {
        ...state,
        totalMaleRanking: payload.totalMaleRanking,
        athletes_male: payload.athletes_male,
      };
    case FEMALE_RANKING_LIST:
      return {
        ...state,
        totalFemaleRanking: payload.totalFemaleRanking,
        athletes_female: payload.athletes_female,
      };
    case HELP_LIST:
      return {
        ...state,
        help_list: payload.help_list.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        ),
      };
    case TERMS_CONDITIONS:
      return {
        ...state,
        terms_conditions: payload.terms_conditions,
      };
    case PRIVACY_POLICY:
      return {
        ...state,
        privacy_policy: payload.privacy_policy,
      };
    case ABOUT_US:
      return {
        ...state,
        about_us: payload.about_us,
      };
    case UPCOMING_TOURNAMENT:
      return {
        ...state,
        totalUpcomingTournaments: payload.totalUpcomingTournaments,
        upcoming_tournament: payload.upcoming_tournament.sort(
          (a, b) => new Date(b.from_date) - new Date(a.from_date),
        ),
      };
    case PREVIOUS_TOURNAMENT:
      return {
        ...state,
        totalTournaments: payload.totalTournaments,
        previous_tournament: payload.previous_tournament.sort(
          (a, b) => new Date(b.from_date) - new Date(a.from_date),
        ),
      };
    case CONTACT_INFO:
      return {
        ...state,
        contact_info: payload.contact_info,
      };

    case CONTACT_US:
      return {
        ...state,
        message: payload.message,
        isError: false,
      };

    case ARENA_TOURNAMENT_LIST:
      return {
        ...state,
        arena_details: payload.arena_details,
        arena_tournament_list: payload.arena_tournament_list.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        ),
      };

    case TOURNAMENT_DETAILS:
      return {
        ...state,
        tournament_details: payload.tournament_details,
      };

    case DATA_FAILED:
      return {
        ...state,
        message: payload.message,
        isError: false,
      };
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: null,
        isError: false,
      };
    case GET_DASHBOARD:
      return {
        ...state,
        arena_count: payload.arena_count,
        athlete_count: payload.athlete_count,
        total_revenue: payload.total_revenue,
      };
    default:
      return state;
  }
}
