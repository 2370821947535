import './index.scss';
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {
  fetchDeleteTournament,
  getTournamentList,
} from '../../../actions/Tournaments';
import DeleteModal from '../../../components/delete-modal';
import {
  CitySelectField,
  SearchInputField,
  StateSelectField,
} from '../../../components/search-filter';
import moment from 'moment';
import {TournamentListPlaceholder} from '../../../components/skeleton-placeholder';
import PagePagination from '../../../components/pagination';
import {
  handleActionCity,
  handleActionCurrentPage,
  handleActionSearch,
  handleActionState,
} from '../../../actions/Actions';
import {
  WEB_SEARCH,
  WEB_CURRENT_PAGE,
  PERPAGE,
} from '../../../services/keywords';

const Tournaments = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    tournaments,
    query,
    state,
    city,
    currentPage,
    totalTournaments,
    isLoading,
  } = useSelector(state => state.Tournaments);

  const getSearchData = JSON.parse(localStorage.getItem(WEB_SEARCH)) || 1;
  const getCurrentPage =
    JSON.parse(localStorage.getItem(WEB_CURRENT_PAGE)) || null;

  const [deleteModal, setDeleteModal] = useState(false);
  const [itemId, setItemId] = useState('');

  const TournamentItems =
    tournaments && tournaments.length > 0 ? (
      tournaments.map(item => (
        <tr key={item?.id}>
          <td data-label={t('Tournament Name')}>
            {item?.championship_name ? item.championship_name : 'N/A'}
          </td>
          <td data-label={t('Tournament Type')}>
            {item?.type?.type_name ? t(item?.type?.type_name) : 'N/A'}
          </td>
          <td data-label={t('From Date')}>
            {item?.from_date
              ? moment(item.from_date).format('DD-MM-YYYY')
              : 'N/A'}
          </td>
          <td data-label={t('From Time')}>
            {item?.from_time
              ? moment(item.from_time, 'HH:mm').format('HH:mm')
              : 'N/A'}
          </td>

          <td data-label={t('No. Duos')}>
            {item?.duo?.duo_number ? item?.duo?.duo_number : 'N/A'}
          </td>
          <td data-label={t('Action')} className="arenaBtns">
            {item?.status == 2 && (
              <>
                <button
                  type="button"
                  onClick={() => {
                    if (item?.types_id === '1' || item?.types_id === 1) {
                      navigate(`/tournaments/double-elimination/${item?.id}`);
                    } else {
                      navigate(`/tournaments/group-phase/${item?.id}`);
                    }
                  }}>
                  <i className="fa-regular fa-play"></i>
                </button>
                <button
                  type="button"
                  onClick={() => handleShowConfirmModal(item.id)}>
                  <i className="fa-regular fa-trash"></i>
                </button>
              </>
            )}
            {item?.status == 1 && (
              <>
                <button
                  type="button"
                  onClick={() => {
                    if (item?.status === '1' || item?.status === 1) {
                      navigate(`/tournaments/teams/${item?.id}`);
                    } else {
                      if (item?.types_id === '1' || item?.types_id === 1) {
                        navigate(`/tournaments/double-elimination/${item?.id}`);
                      } else {
                        navigate(`/tournaments/group-phase/${item?.id}`);
                      }
                    }
                  }}>
                  <i className="fa-regular fa-circle-plus"></i>
                </button>
                <button
                  type="button"
                  onClick={() => navigate(`/tournaments/edit/${item?.id}`)}>
                  <i className="fa-regular fa-pencil"></i>
                </button>
                <button
                  type="button"
                  onClick={() => handleShowConfirmModal(item.id)}>
                  <i className="fa-regular fa-trash"></i>
                </button>
              </>
            )}

            {item?.status == 3 && (
              <button
                type="button"
                onClick={() => {
                  if (item?.types_id === '1' || item?.types_id === 1) {
                    navigate(`/tournaments/double-elimination/${item?.id}`);
                  } else {
                    navigate(`/tournaments/group-phase/${item?.id}`);
                  }
                }}>
                <i className="fa-regular fa-eye"></i>
              </button>
            )}
          </td>
        </tr>
      ))
    ) : (
      <tr className="p-0">
        <td colSpan="6" className="text-center">
          {t('Tournaments')} {t('Not Found')}.
        </td>
      </tr>
    );

  const handleShowConfirmModal = itemId => {
    setDeleteModal(true);
    setItemId(itemId);
  };

  const handleResetFilter = () => {
    dispatch(getTournamentList(1, PERPAGE, {}));
    dispatch(handleActionSearch(''));
    dispatch(handleActionState(''));
    dispatch(handleActionCity(''));
    dispatch(handleActionCurrentPage(1));
    localStorage.removeItem(WEB_SEARCH);
    localStorage.removeItem(WEB_CURRENT_PAGE);
  };
  const onChangePaginate = number => {
    const searchData = {
      query: query,
      state: state,
      city: city,
    };
    dispatch(getTournamentList(number, PERPAGE, searchData));
    dispatch(handleActionSearch(query));
    dispatch(handleActionState(state));
    dispatch(handleActionCity(city));
    dispatch(handleActionCurrentPage(number));

    localStorage.setItem(WEB_SEARCH, JSON.stringify(searchData));
    localStorage.setItem(WEB_CURRENT_PAGE, JSON.stringify(number));
  };

  useEffect(() => {
    if (getCurrentPage || getSearchData) {
      dispatch(getTournamentList(getCurrentPage, PERPAGE, getSearchData));
      dispatch(handleActionCurrentPage(getCurrentPage));
      dispatch(handleActionSearch(getSearchData.query));
      dispatch(handleActionState(getSearchData.state));
      dispatch(handleActionCity(getSearchData.city));
    } else {
      handleResetFilter();
    }
  }, [dispatch]);

  return (
    <>
      <div className="arenaBase">
        <div className="topFlex mb-3">
          <h2 className="admin-page-title mb-3">{t('Tournaments')}</h2>
          <div className="admin-page-header-right">
            <div className="search-filter-bx">
              <div className="search-filter-col state-filter">
                <StateSelectField
                  fieldName={'state'}
                  labelName={t('State')}
                  searchStateTerm={state}
                  handleChange={e =>
                    dispatch(handleActionState(e.target.value))
                  }
                />
              </div>
              <div className="search-filter-col city-filter">
                <CitySelectField
                  fieldName={'city'}
                  labelName={t('City')}
                  selectedStateName={state}
                  searchCityTerm={city}
                  handleChange={e => dispatch(handleActionCity(e.target.value))}
                />
              </div>
              <div className="search-filter-col search-filter">
                <SearchInputField
                  fieldName={'search'}
                  labelName={t('Search')}
                  searchTerm={query}
                  handleChange={e =>
                    dispatch(handleActionSearch(e.target.value))
                  }
                />
                <button
                  type="button"
                  className="btn btn-reset btn-search ms-2"
                  onClick={() => onChangePaginate(1)}>
                  <i className="fa-regular fa-light fa-search"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-reset ms-2"
                  onClick={handleResetFilter}>
                  <i className="fa-regular fa-light fa-rotate-left"></i>
                </button>
                <Link className="addBtns ms-3" to="/tournaments/add">
                  <i className="fa-regular fa-circle-plus"></i>{' '}
                  <span>{t('Add Tournament')}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <table className="mb-3">
          <thead>
            <tr>
              <th scope="col">{t('Tournament Name')}</th>
              <th scope="col">{t('Tournament Type')}</th>
              <th scope="col">{t('From Date')}</th>
              <th scope="col">{t('From Time')}</th>
              <th scope="col">{t('No. Duos')}</th>
              <th scope="col">{t('Action')}</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? <TournamentListPlaceholder /> : TournamentItems}
          </tbody>
        </table>

        <div className="d-flex justify-content-between flex-row-reverse px-4">
          <div className="right-pagination">
            <PagePagination
              totalItems={totalTournaments}
              currentPage={currentPage}
              pageSize={PERPAGE}
              onPageChange={onChangePaginate}
            />
          </div>
          <Link to="/dashboard" className="backtxt">
            <i className="fa-light fa-arrow-left-long"></i> {t('Back')}
          </Link>
        </div>
      </div>
      <DeleteModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        action={() => dispatch(fetchDeleteTournament(itemId))}
      />
    </>
  );
};

export default Tournaments;
