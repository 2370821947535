import './index.scss';
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';

import moment from 'moment';

import {
  CitySelectField,
  SearchInputField,
  StateSelectField,
} from '../../../components/search-filter';
import {AthleteListPlaceholder} from '../../../components/skeleton-placeholder';
import {getPaginationAtheleteList} from '../../../actions/Athletes';
import PagePagination from '../../../components/pagination';

import {
  handleActionCity,
  handleActionCurrentPage,
  handleActionSearch,
  handleActionState,
} from '../../../actions/Actions';
import {
  WEB_SEARCH,
  WEB_CURRENT_PAGE,
  PERPAGE,
} from '../../../services/keywords';
const Athletes = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isLoading,
    totalAthletes,
    athleteList,
    query,
    state,
    city,
    currentPage,
  } = useSelector(state => state.Athletes);

  const getSearchData = JSON.parse(localStorage.getItem(WEB_SEARCH)) || 1;
  const getCurrentPage =
    JSON.parse(localStorage.getItem(WEB_CURRENT_PAGE)) || null;

  const calculateAge = dob => {
    const parsedDob = moment(dob, 'DD-MM-YYYY');
    return moment().diff(parsedDob, 'years', false) + ' ' + t('Years');
  };

  const AthleteItems =
    athleteList.length > 0 ? (
      athleteList.map(item => (
        <tr key={item.id}>
          <td data-label={t('Athlete ID')}>
            {item.user_id ? '#' + item.user_id : 'N/A'}
          </td>
          <td data-label={t('Athlete Name')}>{item.name}</td>
          <td data-label={t('Nick Name')}>
            {item.display_name ? item.display_name : 'N/A'}
          </td>
          <td data-label={t('City/State')}>
            {item.city && item.state ? item.city + '/' + item.state : 'N/A'}
          </td>
          <td data-label={t('FTV Points')}>
            {item.ftv_points ? item.ftv_points : 'N/A'}
          </td>
          <td data-label={t('Age')}>
            {item.dob
              ? calculateAge(moment(item?.dob).format('DD-MM-YYYY'))
              : 'N/A'}
          </td>
          {/* <td data-label={t("Championship Assigned")}>{item.championship_assigned}</td> */}
          <td data-label={t('Action')} className="arenaBtns">
            <button
              type="button"
              onClick={() => navigate(`/athelets/view/${item.id}`)}>
              <i className="fa-regular fa-eye"></i>
            </button>
            {/* <button type='button'><i className="fa-regular fa-trash"></i></button> */}
          </td>
        </tr>
      ))
    ) : (
      <tr className="p-0">
        <td colSpan="7" className="text-center">
          {t('Athletes')} {t('Not Found')}.
        </td>
      </tr>
    );

  const handleResetFilter = () => {
    dispatch(getPaginationAtheleteList(1, PERPAGE, {}));
    dispatch(handleActionSearch(''));
    dispatch(handleActionState(''));
    dispatch(handleActionCity(''));
    dispatch(handleActionCurrentPage(1));
    localStorage.removeItem(WEB_SEARCH);
    localStorage.removeItem(WEB_CURRENT_PAGE);
  };

  const onChangePaginate = number => {
    const searchData = {
      query: query,
      state: state,
      city: city,
    };

    dispatch(getPaginationAtheleteList(number, PERPAGE, searchData));
    dispatch(handleActionSearch(query));
    dispatch(handleActionState(state));
    dispatch(handleActionCity(city));
    dispatch(handleActionCurrentPage(number));

    localStorage.setItem(WEB_SEARCH, JSON.stringify(searchData));
    localStorage.setItem(WEB_CURRENT_PAGE, JSON.stringify(number));
  };

  useEffect(() => {
    if (getCurrentPage || getSearchData) {
      dispatch(
        getPaginationAtheleteList(getCurrentPage, PERPAGE, getSearchData),
      );
      dispatch(handleActionCurrentPage(getCurrentPage));
      dispatch(handleActionSearch(getSearchData.query));
      dispatch(handleActionState(getSearchData.state));
      dispatch(handleActionCity(getSearchData.city));
    } else {
      handleResetFilter();
    }
  }, [dispatch]);

  return (
    <div className="arenaBase">
      <div className="topFlex mb-3">
        <h2 className="admin-page-title mb-3">{t('Athletes')}</h2>
        <div className="admin-page-header-right">
          <div className="search-filter-bx">
            <div className="search-filter-col state-filter">
              <StateSelectField
                fieldName={'state'}
                labelName={t('State')}
                searchStateTerm={state}
                handleChange={e => dispatch(handleActionState(e.target.value))}
              />
            </div>
            <div className="search-filter-col city-filter">
              <CitySelectField
                fieldName={'city'}
                labelName={t('City')}
                selectedStateName={state}
                searchCityTerm={city}
                handleChange={e => dispatch(handleActionCity(e.target.value))}
              />
            </div>
            <div className="search-filter-col search-filter">
              <SearchInputField
                fieldName={'search'}
                labelName={t('Search')}
                searchTerm={query}
                handleChange={e => dispatch(handleActionSearch(e.target.value))}
              />
              <button
                type="button"
                className="btn btn-reset btn-search ms-2"
                onClick={() => onChangePaginate(1)}>
                <i className="fa-regular fa-light fa-search"></i>
              </button>
              <button
                className="btn btn-reset ms-3"
                onClick={handleResetFilter}>
                <i className="fa-regular fa-light fa-rotate-left"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <table className="mb-3">
        <thead>
          <tr>
            <th scope="col">{t('Athlete ID')}</th>
            <th scope="col">{t('Athlete Name')}</th>
            <th scope="col">{t('Nick Name')}</th>
            <th scope="col">{t('City/State')}</th>
            <th scope="col">{t('FTV Points')}</th>
            <th scope="col">{t('Age')}</th>
            {/* <th scope="col">{t("Championship Assigned")}</th> */}
            <th scope="col">{t('Action')}</th>
          </tr>
        </thead>
        <tbody>{isLoading ? <AthleteListPlaceholder /> : AthleteItems}</tbody>
      </table>

      <div className="d-flex justify-content-between flex-row-reverse px-4">
        <div>
          <PagePagination
            totalItems={totalAthletes}
            currentPage={currentPage}
            pageSize={PERPAGE}
            onPageChange={onChangePaginate}
          />
        </div>

        <Link to="/dashboard" className="backtxt">
          <i className="fa-light fa-arrow-left-long"></i> {t('Back')}
        </Link>
      </div>
    </div>
  );
};

export default Athletes;
