import {SR_TOKEN, SR_USER} from './keywords';

export default function authHeader() {
  const user = JSON.parse(localStorage.getItem(SR_USER));
  const token = JSON.parse(localStorage.getItem(SR_TOKEN));

  if (user && token) {
    return {Authorization: 'Bearer ' + token};
  } else {
    return {};
  }
}
