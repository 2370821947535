import React, {useState} from 'react';
import './index.scss';
import {Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import Select from 'react-select';
import {useDispatch} from 'react-redux';
import DatePicker from 'react-datepicker';
import * as yup from 'yup';
import {useFormik} from 'formik';
import moment from 'moment';
import {
  fetchGroupPhaseWinnerUpdateMatch,
  fetchDoubleEliminationWinnerUpdateMatch,
} from '../../../../../../actions/Tournaments';
import AthleteGameHeader from '../match-header';
import {SelectStyles, Select2Styles} from '../../../teams/SelectStyles';
import {useParams} from 'react-router-dom';
import MatchUpdateConfirmModal from './match-update-confirm';

const MatchUpdateModal = ({
  openUpdateModal,
  setOpenUpdateModal,
  matchSeed,
  tournamentId,
  tournamentType,
  tournamentStatus,
  matchNumber,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {id} = useParams();
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [matchUpdateData, setMatchUpdateData] = useState();
  const [matchWiningScore, setMatchWiningScore] = useState();

  const participateOptions = () => {
    if (matchSeed?.team_id1 == null || matchSeed?.team_id2 == null) {
      return [];
    } else {
      let temp1 = {
        id: matchSeed?.team1?.id,
        value: matchSeed?.team1?.id,
        label: `${t('Team')} ${matchSeed.team1.team_division_id}`,
      };
      let temp2 = {
        id: matchSeed?.team2?.id,
        value: matchSeed?.team2?.id,
        label: `${t('Team')} ${matchSeed.team2.team_division_id}`,
      };

      return [temp1, temp2];
    }
  };

  const formik = useFormik({
    initialValues: {
      // participant1: "",
      // participant2: "",
      match_id: matchSeed?.id ? matchSeed?.id : '',
      team1_points: '',
      team2_points: '',
      winner_team: '',
      winning_points: '',
      losing_points: '',
      date: null,
      time: null,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      winner_team: yup
        .object()
        .nullable()
        .required(t('Please select a winning team.'))
        .shape({
          value: yup.string().required(t('Please select a winning team.')),
          label: yup.string().required(t('Please select a winning team.')),
        }),
      team1_points: yup
        .number()
        .min(1, t('Points must be at least 1'))
        .max(30, t('Points must be 30 or less'))
        .required(t('Please enter points for Team')),
      team2_points: yup
        .number()
        .min(1, t('Points must be at least 1'))
        .max(30, t('Points must be 30 or less'))
        .required(t('Please enter points for Team')),
    }),
    onSubmit: async values => {
      const matchUpdateObj = {
        played: true,
        tournamentId: tournamentId,
        match_id: values.match_id,
        winning_team_id: values.winner_team.id,
        team1_points: values.team1_points,
        team2_points: values.team2_points,
        winning_points:
          values.winner_team.id == matchSeed.team_id1
            ? values.team1_points
            : values.team2_points,
        losing_points:
          values.winner_team.id == matchSeed.team_id1
            ? values.team2_points
            : values.team1_points,
        date: values.date ? moment(values.date).format('DD-MM-YYYY') : null,
        time: values.time ? values.time : null,
      };
      validate(matchUpdateObj);

      if (!validate(matchUpdateObj)) {
        setMatchUpdateData(matchUpdateObj);
        setUpdateConfirmModal(true);
        setMatchWiningScore(false);
      } else {
        setUpdateConfirmModal(true);
        setMatchWiningScore(true);
      }
    },
  });

  const validate = values => {
    if (values.winning_team_id === matchSeed.team_id1) {
      if (values.team1_points < values.team2_points) return true;
    } else if (values.winning_team_id === matchSeed.team_id2) {
      if (values.team2_points < values.team1_points) return true;
    }
    return false;
  };

  return (
    <>
      <Modal
        show={openUpdateModal}
        onHide={() => {
          setOpenUpdateModal(false);
          formik.resetForm();
        }}
        keyboard={false}
        size="lg"
        className="game-popup"
        centered>
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title as="h5">{matchNumber}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          <form onSubmit={formik.handleSubmit}>
            <AthleteGameHeader matchSeed={matchSeed} />

            <div className="px-4 py-4 game-popup-form-style">
              <div className="row">
                <div className="col-3 d-flex align-items-center">
                  <label>{t('Winner')}</label>
                </div>
                <div className="col-9">
                  <Select
                    options={participateOptions()}
                    onChange={option =>
                      formik.setFieldValue('winner_team', option)
                    }
                    value={formik.values.winner}
                    styles={{...SelectStyles, ...Select2Styles}}
                    placeholder={`--${t('Select Winner')}--`}
                    className="custom-select-style"
                  />
                  {formik.errors.winner_team && formik.touched.winner_team ? (
                    <div className="errorTxt">
                      {formik.errors.winner_team.value ||
                        formik.errors.winner_team.label ||
                        formik.errors.winner_team}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-3 d-flex align-items-center">
                  <label>{t('Score')}</label>
                </div>
                <div className="col-9">
                  <div className="score-row">
                    <div className="col">
                      <input
                        type="number"
                        name="team1_points"
                        max={30}
                        onChange={e => {
                          if (e.target.value <= 30) formik.handleChange(e);
                        }}
                        value={formik.values.team1_points}
                        autoComplete="off"
                        className="form-control"
                        placeholder="0"
                      />
                      {formik.errors.team1_points &&
                      formik.touched.team1_points ? (
                        <div className="errorTxt">
                          {formik.errors.team1_points}
                        </div>
                      ) : null}
                    </div>
                    <div className="colon">:</div>
                    <div className="col">
                      <input
                        type="number"
                        name="team2_points"
                        max={30}
                        onChange={e => {
                          if (e.target.value <= 30) formik.handleChange(e);
                        }}
                        value={formik.values.team2_points}
                        autoComplete="off"
                        className="form-control"
                        placeholder="0"
                      />
                      {formik.errors.team2_points &&
                      formik.touched.team2_points ? (
                        <div className="errorTxt">
                          {formik.errors.team2_points}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3 d-flex align-items-center">
                  <label>{t('Date')}</label>
                </div>
                <div className="col-9">
                  <DatePicker
                    autoComplete="off"
                    className="form-control date-icon"
                    dateFormat="dd-MM-yyyy"
                    placeholderText={t('Select date')}
                    minDate={new Date()}
                    name="date"
                    value={formik.values.date}
                    selected={formik.values.date}
                    onChange={date => formik.setFieldValue('date', date)}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3 d-flex align-items-center">
                  <label>{t('Time')}</label>
                </div>
                <div className="col-9">
                  <DatePicker
                    autoComplete="off"
                    timeIntervals={15}
                    timeCaption="Time"
                    timeFormat="HH:mm"
                    placeholderText={t('Select time')}
                    showTimeSelectOnly
                    showTimeSelect
                    className="form-control time-icon"
                    name="time"
                    value={
                      formik.values.time
                        ? moment
                            .utc(`${formik.values.time}`, 'HH:mm')
                            .format('HH:mm')
                        : null
                    }
                    onChange={time =>
                      formik.setFieldValue('time', moment(time).format('HH:mm'))
                    }
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="px-4 justify-content-center">
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => formik.submitForm()}>
            <span>{t('Submit')}</span>
          </button>
        </Modal.Footer>
      </Modal>
      {updateConfirmModal && (
        <MatchUpdateConfirmModal
          matchWiningScore={matchWiningScore}
          updateConfirmModal={updateConfirmModal}
          setUpdateConfirmModal={setUpdateConfirmModal}
          confirmUpdateAction={() => {
            if (tournamentType === 'Group Phase') {
              dispatch(
                fetchGroupPhaseWinnerUpdateMatch(
                  matchUpdateData,
                  formik,
                  setOpenUpdateModal,
                  setUpdateConfirmModal,
                ),
              );
            }
            if (tournamentType === 'Double Elimination') {
              dispatch(
                fetchDoubleEliminationWinnerUpdateMatch(
                  matchUpdateData,
                  formik,
                  setOpenUpdateModal,
                  setUpdateConfirmModal,
                ),
              );
            }
          }}
        />
      )}
    </>
  );
};

export default MatchUpdateModal;
