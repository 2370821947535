import axios from 'axios';
import authHeader from './auth-header';
import {toast} from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL + 'arena/';

const ATHLETE_URL = process.env.REACT_APP_API_URL + 'athlete/';

export const fetchAthleteList = () => {
  return axios
    .get(API_URL + 'athletes-view', {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Athlete list failed: ${error.message}`);
    });
};

export const fetchPaginationAthleteList = (page, perPages, searchData) => {
  return axios
    .post(
      API_URL + `athlete-view?page=${page}&pageSize=${perPages}`,
      searchData,
      {headers: authHeader()},
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Athlete list failed: ${error.message}`);
    });
};

export const fetchAthleteView = itemId => {
  return axios
    .get(API_URL + `athletes-profile/${itemId}`, {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Athlete view failed: ${error.message}`);
    });
};

export const fetchAthleteCountsApi = userId => {
  return axios
    .get(ATHLETE_URL + `match-standing/${userId}`, {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Athlete matchStanding failed: ${error.message}`);
    });
};

export const fetchAthleteRanking = userId => {
  return axios
    .post(
      ATHLETE_URL + `ranking`,
      {athlete_id: userId},
      {headers: authHeader()},
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Athlete ranking failed: ${error.message}`);
    });
};
