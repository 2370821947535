import React, {useState} from 'react';
import './index.scss';
import {useLocation, useNavigate} from 'react-router-dom';
import {Navigation} from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import Logout from '../../../auth/logout';
import {useTranslation} from 'react-i18next';
import {
  handleActionCity,
  handleActionCurrentPage,
  handleActionSearch,
  handleActionState,
} from '../../../../actions/Actions';
import {
  WEB_CURRENT_PAGE,
  WEB_SEARCH,
  PERPAGE,
} from '../../../../services/keywords';
import {useDispatch} from 'react-redux';
import {getArenaList} from '../../../../actions/Arenas';
import {getTournamentList} from '../../../../actions/Tournaments';
import {getPaginationAtheleteList} from '../../../../actions/Athletes';

const Sidebar = ({isSidebarOpen, isWideScreen, setIsSidebarOpen}) => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logoutModal, setLogoutModal] = useState(false);

  return (
    <>
      <div
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className={`sidebar-overlay z-2 ${
          isWideScreen
            ? 'd-none d-lg-block'
            : isSidebarOpen
            ? 'ease-out'
            : 'ease-in top-0 bottom-0 start-0 end-0'
        }`}></div>
      <aside
        className={`navLeft z-3 ${
          isWideScreen
            ? 'd-none d-lg-block'
            : isSidebarOpen
            ? 'ease-out'
            : 'ease-in'
        }`}>
        <Navigation
          activeItemId={location.pathname}
          items={[
            {
              title: t('My Profile'),
              itemId: '/my-profile',
              elemBefore: () => <i className="fa-regular fa-user" />,
            },
            {
              title: t('Arenas'),
              itemId: '/arena',
              elemBefore: () => <i className="fa-solid fa-user-tie" />,
            },
            {
              title: t('Athletes'),
              itemId: '/athelets',
              elemBefore: () => <i className="fa-solid fa-person-running" />,
            },
            {
              title: t('Tournaments'),
              itemId: '/tournaments',
              elemBefore: () => <i className="fa-regular fa-calendar-days" />,
            },
            {
              title: t('Payment'),
              itemId: '/payment',
              elemBefore: () => <i className="fa-regular fa-credit-card" />,
            },
            // {
            //     title: t("News"),
            //     itemId: "/news",
            //     elemBefore: () => <i className="fa-regular fa-newspaper" />
            // },
          ]}
          onSelect={({itemId}) => {
            navigate(itemId);
            setIsSidebarOpen(!isSidebarOpen);
            dispatch(handleActionSearch(''));
            dispatch(handleActionState(''));
            dispatch(handleActionCity(''));
            dispatch(handleActionCurrentPage(1));
            localStorage.removeItem(WEB_SEARCH);
            localStorage.removeItem(WEB_CURRENT_PAGE);
            if (itemId === '/athletes') {
              dispatch(getPaginationAtheleteList(1, PERPAGE, {}));
            } else if (itemId === '/arena') {
              dispatch(getArenaList(1, PERPAGE, {}));
            } else if (itemId === '/tournaments') {
              dispatch(getTournamentList(1, PERPAGE, {}));
            }
          }}
        />
        <button className="logoutbtn" onClick={() => setLogoutModal(true)}>
          <i className="fa-solid fa-right-from-bracket"></i> {t('Logout')}
        </button>
        <Logout logoutModal={logoutModal} setLogoutModal={setLogoutModal} />
      </aside>
    </>
  );
};

export default Sidebar;
