import React, {useState} from 'react';
import MatchUpdateModal from '../components/match-update-modal';
import MatchViewModal from '../components/match-view-modal';
import {useTranslation} from 'react-i18next';
const MatchesBracket = ({
  rounds,
  groupName,
  tournamentId,
  tournamentType,
  tournamentStatus,
}) => {
  const {t} = useTranslation();

  const groups = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [matchSeed, setMatchSeed] = useState();
  const [matchNo, setMatchNo] = useState();

  const totalMatchNumber = order => {
    const index = groups.indexOf(groupName);
    return order + index * rounds.length;
  };

  const handleUpdateModal = (data, index) => {
    setOpenUpdateModal(true);
    setMatchSeed(data);
    setMatchNo(index);
  };
  const handleViewModal = (data, index) => {
    setOpenViewModal(true);
    setMatchSeed(data);
    setMatchNo(index);
  };

  // console.log("seed===>", rounds)
  // console.log("tournament===>", tournament)

  return (
    <>
      <div className="game-group-phase-wrapper">
        {rounds &&
          rounds.map((seed, index) => {
            return (
              <div className="game-group-phase" key={seed?.id}>
                <span className="match-no">
                  {seed?.order ? totalMatchNumber(index + 1) : ''}
                </span>
                <div
                  className="seed-item"
                  onClick={() => {
                    if (
                      tournamentStatus === 1 &&
                      seed?.team_id1 != null &&
                      seed?.team_id2 != null
                    ) {
                      handleViewModal(seed, index);
                    } else {
                      if (
                        tournamentStatus === 2 &&
                        seed?.played === '0' &&
                        seed?.team_id1 != null &&
                        seed?.team_id2 != null
                      ) {
                        handleUpdateModal(seed, index);
                      } else if (
                        tournamentStatus === 2 &&
                        seed?.played === 'true' &&
                        seed?.team_id1 != null &&
                        seed?.team_id2 != null
                      ) {
                        handleViewModal(seed, index);
                      } else {
                        handleViewModal(seed, index);
                      }
                    }
                  }}>
                  <div
                    className={`game-style seed-team game-1 ${
                      seed?.played === true || seed?.played === 'true'
                        ? seed?.team_id1 === seed?.winning_team_id
                          ? 'win'
                          : 'lose'
                        : ''
                    }`}>
                    {/* <span className='player-id'>{seed?.team_id1 ? "#" + seed?.team_id1 : "--"}</span> */}
                    {/* <span className="player-name">
                      {seed?.team1?.team_division_id
                        ? t('Team') + ' ' + seed?.team1?.team_division_id
                        : null || t('No Team')}
                    </span> */}

                    {seed?.team_id1 != null ? (
                      <span className="player-name">
                        {seed.team1?.athlete_player1_id
                          ? seed?.team1?.athlete_player1?.display_name
                          : '--'}{' '}
                        x{' '}
                        {seed.team1?.athlete_player2_id
                          ? seed?.team1?.athlete_player2?.display_name
                          : '--'}
                      </span>
                    ) : (
                      <span className="player-name">{t('No Team')}</span>
                    )}
                    {seed?.played === true || seed?.played === 'true' ? (
                      <span className="player-score">
                        {seed?.team_id1 === seed?.winning_team_id
                          ? seed?.winning_points
                          : seed?.losing_points}
                      </span>
                    ) : (
                      <span className="player-score">--</span>
                    )}
                  </div>
                  <div
                    className={`game-style seed-team game-2 ${
                      seed?.played === true || seed?.played === 'true'
                        ? seed?.team_id2 === seed?.winning_team_id
                          ? 'win'
                          : 'lose'
                        : ''
                    }`}>
                    {/* <span className='player-id'>{seed?.team_id2 ? "#" + seed?.team_id2 : "--"}</span> */}
                    {/* <span className="player-name">
                      {seed?.team2?.team_division_id
                        ? t('Team') + ' ' + seed?.team2?.team_division_id
                        : null || t('No Team')}
                    </span> */}

                    {seed?.team_id2 != null ? (
                      <span className="player-name">
                        {seed.team2?.athlete_player1_id
                          ? seed?.team2?.athlete_player1?.display_name
                          : '--'}{' '}
                        x{' '}
                        {seed.team2?.athlete_player2_id
                          ? seed?.team2?.athlete_player2?.display_name
                          : '--'}
                      </span>
                    ) : (
                      <span className="player-name">{t('No Team')}</span>
                    )}
                    {seed?.played === true || seed?.played === 'true' ? (
                      <span className="player-score">
                        {seed?.team_id2 === seed?.winning_team_id
                          ? seed?.winning_points
                          : seed?.losing_points}
                      </span>
                    ) : (
                      <span className="player-score">--</span>
                    )}
                  </div>
                </div>
              </div>
            );
          })}

        {openUpdateModal && (
          <MatchUpdateModal
            openUpdateModal={openUpdateModal}
            setOpenUpdateModal={setOpenUpdateModal}
            matchSeed={matchSeed}
            tournamentId={tournamentId}
            tournamentType={tournamentType}
            tournamentStatus={tournamentStatus}
            matchNumber={t('Match') + ' ' + totalMatchNumber(matchNo + 1)}
          />
        )}
        {openViewModal && (
          <MatchViewModal
            openViewModal={openViewModal}
            setOpenViewModal={setOpenViewModal}
            matchSeed={matchSeed}
            tournamentId={tournamentId}
            tournamentType={tournamentType}
            tournamentStatus={tournamentStatus}
            matchNumber={t('Match') + ' ' + totalMatchNumber(matchNo + 1)}
          />
        )}
      </div>
    </>
  );
};

export default MatchesBracket;
