import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';
import {SR_DEVICE_TOKEN} from './services/keywords';

// Firebase configuration
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// Get Firebase Messaging instance
export const messaging = getMessaging(firebaseApp);

export const fetchToken = async setTokenFound => {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    try {
      const newSw = await navigator.serviceWorker.register(
        `${process.env.REACT_APP_PUBLIC_URL}/firebase-messaging-sw.js`,
      );
      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID_KEY,
        serviceWorkerRegistration: newSw,
      });

      if (currentToken) {
        console.log('Current token for client:', currentToken);
        localStorage.setItem(SR_DEVICE_TOKEN, JSON.stringify(currentToken));
        setTokenFound(true);
      } else {
        console.log(
          'No registration token available. Request permission to generate one.',
        );
        setTokenFound(false);
      }
    } catch (err) {
      console.log('An error occurred while retrieving token.', err);
      setTokenFound(false);
    }
  } else {
    console.log(
      'Service Worker or Push messaging is not supported in this browser.',
    );
    setTokenFound(false);
  }
};

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
