import {
  ARENA_SUCCESS,
  ARENA_VIEW,
  ATHLETE_SUCCESS,
  ATHLETE_VIEW,
  CATEGORIES_SUCCESS,
  CLEAR_ALL_NOTIFICATION,
  CLEAR_MESSAGE,
  DUOS_SUCCESS,
  EMAIL_VERIFICATION_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  GROUP_PHASE_MATCHES_BRACKET,
  GROUP_PHASE_STANDING,
  GROUP_PHASE_UPDATE_MATCH,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  NEWS_VIEW,
  NOTIFICATIONS_SUCCESS,
  PROFILE_UPDATE_SUCCESS,
  RECIEVED_NOTIFICATION,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SET_MESSAGE,
  START_LOADING,
  STOP_LOADING,
  TEAM_CREATE,
  TEAM_SUCCESS,
  TOURNAMENT_ADD,
  TOURNAMENT_DELETE,
  TOURNAMENT_EDIT,
  TOURNAMENT_LIST,
  TOURNAMENT_VIEW,
  TYPES_SUCCESS,
  DOUBLE_ELIMINATION_MATCHES_BRACKET,
  DOUBLE_ELIMINATION_UPDATE_MATCH,
  RANKING_LIST,
  NEWS_LIST,
  TERMS_CONDITIONS,
  PRIVACY_POLICY,
  ABOUT_US,
  HELP_LIST,
  UPCOMING_TOURNAMENT,
  PREVIOUS_TOURNAMENT,
  ARENAS_LIST,
  ARENAS_VIEW,
  CONTACT_INFO,
  CONTACT_US,
  USER_PROFILE_SUCCESS,
  ARENA_TOURNAMENT_LIST,
  TOURNAMENT_DETAILS,
  FINAL_MATCH_LIST,
  START_TOURNAMENT,
  ATHLETE_COUNT,
  ATHLETE_RANKING,
  OPENING_HOURS_UPDATE,
  PAYMENT_AMOUNT,
  PAYMENT_SUCCESS,
  AVAILABLE_ATHLETE_LIST,
  DATA_FAILED,
  GET_DASHBOARD,
  MALE_RANKING_LIST,
  FEMALE_RANKING_LIST,
  ATHLETE_LIST,
  CURRENT_PAGE,
  SEARCH_FIELD,
  STATE_FIELD,
  CITY_FIELD,
} from './Types';

// Action creator for message
export const setMessage = data => ({
  type: SET_MESSAGE,
  payload: {
    message: data.message,
  },
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});

// Action creator for loader
export const startLoading = () => ({
  type: START_LOADING,
});

export const stopLoading = () => ({
  type: STOP_LOADING,
});

export const handleFetchDataFailed = message => {
  return {
    type: DATA_FAILED,
    payload: {
      message: message,
    },
  };
};

// Action creator for login
export const loginSuccess = data => {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      user: data.data,
      message: data.message,
    },
  };
};

export const loginFail = message => {
  return {
    type: LOGIN_FAIL,
    payload: {
      message: message,
    },
  };
};
// Action creator for register
export const registerSuccess = data => {
  return {
    type: REGISTER_SUCCESS,
    payload: {
      message: data.message,
    },
  };
};

export const registerFail = message => {
  return {
    type: REGISTER_FAIL,
    payload: {
      message: message,
    },
  };
};

// Action creator for logout
export const logoutAuth = () => {
  return {
    type: LOGOUT,
  };
};

// Action creator for Profile update
export const userProfileSuccess = data => {
  return {
    type: USER_PROFILE_SUCCESS,
    payload: {
      user: data.data,
    },
  };
};

export const profileUpdateSuccess = data => {
  return {
    type: PROFILE_UPDATE_SUCCESS,
    payload: {
      user: data.data,
      message: data.message,
    },
  };
};

export const handleOpeningHoursUpdate = data => {
  return {
    type: OPENING_HOURS_UPDATE,
    payload: {
      dayData: data.data,
    },
  };
};

// Action creator for email verification
export const emailVerificationSuccess = data => {
  return {
    type: EMAIL_VERIFICATION_SUCCESS,
    payload: {
      message: data.message,
    },
  };
};

// Action creator for forgot password
export const forgotPasswordSuccess = data => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: {
      message: data.message,
    },
  };
};

// Action creator for Arena
export const handleArenaSuccess = data => {
  return {
    type: ARENA_SUCCESS,
    payload: {
      arenas: data.data.data ? data.data.data : [],
      currentPage: data.data.current_page ? data.data.current_page : 1,
      totalArenas: data.data.total ? data.data.total : 1,
    },
  };
};

export const handleArenaView = data => {
  return {
    type: ARENA_VIEW,
    payload: {
      details: data.data,
    },
  };
};
// Action creator for Athlete
export const handleAthletePaginationSuccess = data => {
  return {
    type: ATHLETE_LIST,
    payload: {
      athleteList: data.data.data,
      currentPage: data.data.current_page,
      totalAthletes: data.data.total,
    },
  };
};

export const handleAthleteSuccess = data => {
  return {
    type: ATHLETE_SUCCESS,
    payload: {
      athletes: data.data,
    },
  };
};

export const handleAthleteView = data => {
  return {
    type: ATHLETE_VIEW,
    payload: {
      details: data.data,
    },
  };
};

export const handleAthleteCount = data => {
  return {
    type: ATHLETE_COUNT,
    payload: {
      countingData: data.data,
    },
  };
};

export const handleAthleteRanking = data => {
  return {
    type: ATHLETE_RANKING,
    payload: {
      rankingData: data.data,
    },
  };
};

// Action creator for News
export const handleNewsList = data => {
  return {
    type: NEWS_LIST,
    payload: {
      news_list: data.data.data,
      totalNews: data.data.total,
    },
  };
};

export const handleNewsView = data => {
  return {
    type: NEWS_VIEW,
    payload: {
      news_details: data.data,
    },
  };
};

export const handleRankingSuccess = data => {
  return {
    type: RANKING_LIST,
    payload: {
      athletes_female: data.data.athletes_female.data,
      totalFemaleRanking: data.data.athletes_female.total,
      athletes_male: data.data.athletes_male.data,
      totalMaleRanking: data.data.athletes_male.total,
    },
  };
};

export const handleMaleRankingSuccess = data => {
  return {
    type: MALE_RANKING_LIST,
    payload: {
      athletes_male: data.data.athletes_male.data,
      totalMaleRanking: data.data.athletes_male.total,
    },
  };
};
export const handleFemaleRankingSuccess = data => {
  return {
    type: FEMALE_RANKING_LIST,
    payload: {
      athletes_female: data.data.athletes_female.data,
      totalFemaleRanking: data.data.athletes_female.total,
    },
  };
};
export const handleTermsConditionView = data => {
  return {
    type: TERMS_CONDITIONS,
    payload: {
      terms_conditions: data.data,
    },
  };
};

export const handlePrivacyPolicyView = data => {
  return {
    type: PRIVACY_POLICY,
    payload: {
      privacy_policy: data.data,
    },
  };
};

export const handleAboutUsView = data => {
  return {
    type: ABOUT_US,
    payload: {
      about_us: data.data,
    },
  };
};

export const handleHelpView = data => {
  return {
    type: HELP_LIST,
    payload: {
      help_list: data.data,
    },
  };
};
export const handleUpcomingTournamentView = data => {
  return {
    type: UPCOMING_TOURNAMENT,
    payload: {
      upcoming_tournament: data.data.data,
      totalUpcomingTournaments: data.data.total,
    },
  };
};
export const handlePreviousTournamentView = data => {
  return {
    type: PREVIOUS_TOURNAMENT,
    payload: {
      previous_tournament: data.data.data,
      totalTournaments: data.data.total,
    },
  };
};
export const handleArenasListView = data => {
  return {
    type: ARENAS_LIST,
    payload: {
      arenas_list: data.data.data,
      totalArenas: data.data.total,
    },
  };
};
export const handleArenasView = data => {
  return {
    type: ARENAS_VIEW,
    payload: {
      arena_details: data.data,
    },
  };
};

export const handleContactInfo = data => {
  return {
    type: CONTACT_INFO,
    payload: {
      contact_info: data.data,
    },
  };
};

export const handleContactUsSuccess = data => {
  return {
    type: CONTACT_US,
    payload: {
      message: data.message,
    },
  };
};

// Action creator for Categories
export const handleDuosSuccess = data => {
  return {
    type: DUOS_SUCCESS,
    payload: {
      duoslist: data.data,
    },
  };
};

export const handleCategoriesSuccess = data => {
  return {
    type: CATEGORIES_SUCCESS,
    payload: {
      categorieslist: data.data,
    },
  };
};

export const handleTypesSuccess = data => {
  return {
    type: TYPES_SUCCESS,
    payload: {
      typeslist: data.data,
    },
  };
};

export const handleNotificationsSuccess = data => {
  return {
    type: NOTIFICATIONS_SUCCESS,
    payload: {
      notificationlist: data.data,
    },
  };
};

export const handleRecievedNotification = data => {
  return {
    type: RECIEVED_NOTIFICATION,
    payload: {
      notification: data,
    },
  };
};

export const handleClearAllNotification = data => {
  return {
    type: CLEAR_ALL_NOTIFICATION,
    payload: {
      notificationlist: data.data,
    },
  };
};

export const handleTeamSuccess = data => {
  return {
    type: TEAM_SUCCESS,
    payload: {
      teamslist: data.data,
    },
  };
};

// Action creator for tournaments
export const handleTournamentList = data => {
  return {
    type: TOURNAMENT_LIST,
    payload: {
      tournaments: data.data.data ? data.data.data : [],
      currentPage: data.data.current_page ? data.data.current_page : 1,
      totalTournaments: data.data.total ? data.data.total : 1,
      message: data.message,
    },
  };
};

export const handleAddTournament = data => {
  return {
    type: TOURNAMENT_ADD,
    payload: {
      tournaments: data.data,
      message: data.message,
    },
  };
};

export const handleUpdateTournament = data => {
  return {
    type: TOURNAMENT_EDIT,
    payload: {
      id: data.data.id,
      updateData: data.data,
      message: data.message,
    },
  };
};

export const handleDeleteTournament = (itemId, message) => {
  return {
    type: TOURNAMENT_DELETE,
    payload: {
      id: itemId,
      message: message,
    },
  };
};

export const handleTournamentView = data => {
  return {
    type: TOURNAMENT_VIEW,
    payload: {
      details: data.data,
    },
  };
};

export const handleCreateTeam = data => {
  return {
    type: TEAM_CREATE,
    payload: {
      tournamentId: Number(data.data[0].tournament_id),
      teamData: data.data,
      message: data.message,
    },
  };
};

export const handleAvailableAthleteList = data => {
  return {
    type: AVAILABLE_ATHLETE_LIST,
    payload: {
      availableathletelist: data.data,
    },
  };
};

export const handleGroupPhaseBrackets = data => {
  return {
    type: GROUP_PHASE_MATCHES_BRACKET,
    payload: {
      tournamentMatch: data.data.tournament_match,
      tournament: data.data.tournament,
    },
  };
};

export const handleGroupPhaseStanding = data => {
  return {
    type: GROUP_PHASE_STANDING,
    payload: {
      tournamentStanding: data.data.standings,
      tournament: data.data.tournament,
    },
  };
};

export const handleStartTournament = data => {
  return {
    type: START_TOURNAMENT,
    payload: {
      tournament: data.data,
    },
  };
};

export const handleGroupPhaseUpdateMatch = data => {
  return {
    type: GROUP_PHASE_UPDATE_MATCH,
    payload: {
      matchUpdateData: data.data.tournament_match,
      standingUpdateData: data.data.standings,
    },
  };
};

export const handleDoubleEliminationBrackets = data => {
  return {
    type: DOUBLE_ELIMINATION_MATCHES_BRACKET,
    payload: {
      tournamentMatch: data.data.tournament_match,
      tournament: data.data.tournament,
      winningStages: data.data.winner_stage_count,
      losingStages: data.data.looser_stage_count,
    },
  };
};

export const handleFinalList = data => {
  return {
    type: FINAL_MATCH_LIST,
    payload: {
      tournamentMatch: data.data.tournament_match
        ? data.data.tournament_match
        : [],
      basePoints: data.data.base_points,
    },
  };
};

export const handleDoubleEliminationUpdateMatch = data => {
  return {
    type: DOUBLE_ELIMINATION_UPDATE_MATCH,
    payload: {
      doublematchUpdateData: data.data.tournament_match,
      winnerMatchUpdate: data.data?.winning_team_next_match,
      looserMatchUpdate: data.data?.loosing_team_next_match,
    },
  };
};

export const handleArenaTournamentList = data => {
  return {
    type: ARENA_TOURNAMENT_LIST,
    payload: {
      arena_details: data.arena_details,
      arena_tournament_list: data.data,
    },
  };
};

export const handleArenaTournamentDetails = data => {
  return {
    type: TOURNAMENT_DETAILS,
    payload: {
      tournament_details: data.data,
    },
  };
};

export const paymentAmountData = data => {
  return {
    type: PAYMENT_AMOUNT,
    payload: {
      paymentAmount: data?.data?.tournament_fees,
      month: data?.data?.month,
      message: data?.message,
    },
  };
};

export const paymentSuccessData = approval_url => {
  return {
    type: PAYMENT_SUCCESS,
    payload: {
      approvalUrl: approval_url,
    },
  };
};

export const handleGetDashboard = data => {
  return {
    type: GET_DASHBOARD,
    payload: {
      arena_count: data.data.arena_count,
      athlete_count: data.data.athlete_count,
      total_revenue: data.data.total_revenue,
    },
  };
};

export const handleActionCurrentPage = number => {
  return {
    type: CURRENT_PAGE,
    payload: {
      currentPage: number,
    },
  };
};

export const handleActionSearch = value => {
  return {
    type: SEARCH_FIELD,
    payload: {
      query: value || '',
    },
  };
};
export const handleActionState = value => {
  return {
    type: STATE_FIELD,
    payload: {
      state: value || '',
    },
  };
};
export const handleActionCity = value => {
  return {
    type: CITY_FIELD,
    payload: {
      city: value || '',
    },
  };
};
