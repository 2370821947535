import {
  fetchAthleteList,
  fetchAthleteView,
  fetchAthleteCountsApi,
  fetchAthleteRanking,
  fetchPaginationAthleteList,
} from '../services/athlete.service';
import {
  clearMessage,
  handleAthleteSuccess,
  handleAthleteView,
  startLoading,
  stopLoading,
  handleAthleteCount,
  handleAthleteRanking,
  handleFetchDataFailed,
  handleAthletePaginationSuccess,
} from './Actions';

export const getPaginationAtheleteList =
  (page, perPages, searchData) => dispatch => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchPaginationAthleteList(page, perPages, searchData).then(
      data => {
        if (!data) return dispatch(stopLoading());
        if (data?.success) {
          dispatch(handleAthletePaginationSuccess(data));
          dispatch(stopLoading());
        } else {
          dispatch(handleFetchDataFailed(data.message));
          dispatch(stopLoading());
        }
        return Promise.resolve();
      },
      error => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(stopLoading());
        dispatch(dispatch(handleFetchDataFailed(message)));
        return Promise.reject(error);
      },
    );
  };

export const getAthleteList = () => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return fetchAthleteList().then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(handleAthleteSuccess(data));
        dispatch(stopLoading());
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};

export const getAthleteView = itemId => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return fetchAthleteView(itemId).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(handleAthleteView(data));
        dispatch(stopLoading());
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};

export const getAthleteCount = itemId => dispatch => {
  // dispatch(startLoading());
  dispatch(clearMessage());
  return fetchAthleteCountsApi(itemId).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(handleAthleteCount(data));
        dispatch(stopLoading());
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};

export const getAthleteRanking = itemId => dispatch => {
  // dispatch(startLoading());
  dispatch(clearMessage());
  return fetchAthleteRanking(itemId).then(
    data => {
      if (!data) return dispatch(stopLoading());
      if (data?.success) {
        dispatch(handleAthleteRanking(data));
        dispatch(stopLoading());
      } else {
        dispatch(handleFetchDataFailed(data.message));
        dispatch(stopLoading());
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};
