import './index.scss';
import React from 'react';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

const PagePagination = ({totalItems, currentPage, pageSize, onPageChange, loading}) => {
  return (
    <Pagination
      className="pagination-style"
      showTitle={false}
      onChange={page => onPageChange(page)}
      total={totalItems}
      current={currentPage}
      pageSize={pageSize}
      hideOnSinglePage={true}
      prevIcon={<i className="fa-light fa-arrow-left-long"></i>}
      nextIcon={<i className="fa-light fa-arrow-right-long"></i>}
      disabled={loading}
    />
  );
};

export default PagePagination;
