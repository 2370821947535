import axios from 'axios';
import authHeader from './auth-header';
import {toast} from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL + 'arena/';

export const TournamentList = (page, perPages, searchData) => {
  return axios
    .post(
      API_URL + `tournament-list?page=${page}&pageSize=${perPages}`,
      searchData,
      {headers: authHeader()},
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Get tournament failed: ${error.message}`);
    });
};

export const AddTournament = formData => {
  return axios
    .post(API_URL + `tournament-create`, formData, {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Add tournament failed: ${error.message}`);
    });
};

export const UpdateTournament = formData => {
  return axios
    .post(API_URL + `tournament-update`, formData, {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Update tournament failed: ${error.message}`);
    });
};

export const ViewTournamentApi = tournamentId => {
  return axios
    .get(API_URL + `tournament-teams/${tournamentId}`, {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`View tournament failed: ${error.message}`);
    });
};

export const DeleteTournament = itemId => {
  return axios
    .get(API_URL + `tournament-delete/${itemId}`, {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Delete tournament failed: ${error.message}`);
    });
};

export const createTeams = teamData => {
  return axios
    .post(API_URL + `teams`, teamData, {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Team create failed: ${error.message}`);
    });
};

export const getGroupPhaseBrackets = itemId => {
  return axios
    .get(API_URL + `group-phase-bracket-list/${itemId}`, {
      headers: authHeader(),
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Group phase brackets failed: ${error.message}`);
    });
};

export const getGroupPhaseStandings = itemId => {
  return axios
    .get(API_URL + `group-phase-standings/${itemId}`, {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Group phase standings failed: ${error.message}`);
    });
};

export const startTournament = itemId => {
  return axios
    .post(API_URL + `tournament-start`, {id: itemId}, {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Start tournament start failed: ${error.message}`);
    });
};

export const groupPhaseWinnerUpdateMatch = data => {
  return axios
    .post(API_URL + `winner-update-group-phase`, data, {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Group phase winner update match failed: ${error.message}`);
    });
};

export const getGroupPhaseFinalData = tournamentId => {
  return axios
    .get(API_URL + `group-phase-final-view/${tournamentId}`, {
      headers: authHeader(),
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Group phase Final list failed: ${error.message}`);
    });
};

export const getDoubleEliminationBracketData = tournamentId => {
  return axios
    .get(API_URL + `double-elimination-bracket-list/${tournamentId}`, {
      headers: authHeader(),
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`double-elimination-bracket failed: ${error.message}`);
    });
};

export const doubleEliminationWinnerUpdateMatch = data => {
  return axios
    .post(API_URL + `winner-update-double-elimination`, data, {
      headers: authHeader(),
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(
        `double elimination winner update match failed: ${error.message}`,
      );
    });
};

export const getDoubleEliminationFinalData = tournamentId => {
  return axios
    .get(API_URL + `double-elimination-final-view/${tournamentId}`, {
      headers: authHeader(),
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Double-elimination final bracket failed: ${error.message}`);
    });
};

export const availableAthletesList = () => {
  return axios
    .get(API_URL + 'athletes-list', {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Get available athlete list failed: ${error.message}`);
    });
};

export const availableAthleteCheck = athleteId => {
  return axios
    .post(API_URL + `athlete-remove`, {id: athleteId}, {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`available Athlete failed: ${error.message}`);
    });
};
