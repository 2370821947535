import React, { useEffect, useState } from 'react';
import './index.scss';
import noImg from '../../../assets/images/no-user.jpg';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getArenasList } from '../../../actions/Cms';
import BannerBar from '../components/banner-bar';
import bannerImg from '../../../assets/images/arenas-banner.png';
import PagePagination from '../../../components/pagination';
import { FrontEndPlaceholder } from '../../../components/skeleton-placeholder';

const Arenas = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { arenas_list, perPageArenas, isLoading, totalArenas } = useSelector(
    state => state.Cms,
  );
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');

  const onChangePaginate = number => {
    dispatch(getArenasList(number, perPageArenas, {}));
    navigate(`?page=${number}`);
  };

  useEffect(() => {
    dispatch(getArenasList(page ? page : 1, perPageArenas, {}));
  }, [dispatch, page]);


  return (
    <div className="mainBase pt-0">
      <BannerBar
        title={'Battlefields of Excellence'}
        subTitle={'Explore the Arenas of 2024'}
        description={'The right point in your game.'}
        bannerUrl={bannerImg}
        linkUrl={'/championships'}
      />
      <div className="cmn-content-body areans-wrapper">
        <div className="container">
          {isLoading ? (
            <FrontEndPlaceholder />
          ) : (
            <div className="row">
              {arenas_list && arenas_list.length > 0 ? (
                <>
                  {arenas_list.map(item => {
                    return (
                      <div
                        key={item.id}
                        className="col-md-6 col-lg-3 py-3 arena-profile-item">
                        <div
                          className="championCard"
                          onClick={() => navigate(`/arenas/view/${item.id}`)}>
                          <div className="championCardImg height-240">
                            {item.profile?.profile_url ? (
                              <img
                                src={item.profile?.profile_url}
                                alt={item.name}
                              />
                            ) : (
                              <img src={noImg} alt="No user" />
                            )}
                          </div>
                          <div className="cardTxt">
                            <div className="cardHdn">
                              <h6>{item.name}</h6>
                              <p className="arena-location bi bi-geo-alt">
                                {item.address ? item.address : 'N/A'}
                              </p>
                            </div>
                            <div className='cardBtns'>
                              <button className="btn btn-read-more">
                                {t('Read More')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="col-md-12 py-3">
                  <p className="mb-0 text-center">
                    {t('Arenas')} {t('Not Found')}
                  </p>
                </div>
              )}
            </div>
          )}
          <div className="d-flex justify-content-end mt-3">
            <PagePagination
              totalItems={totalArenas}
              currentPage={page ? page : 1}
              pageSize={perPageArenas}
              onPageChange={onChangePaginate}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Arenas;
